import { createStore } from "vuex";

const store = createStore({
    state: {
        loader: {
            isLoading: false,
            showLoader: null,
            loaderSize: 0,
            opacity: 0
        }
        // isConfirmed: false
    },

    mutations: {
        loading(state, isLoading) {
            state.loader.isLoading = isLoading;
            if (isLoading) {
                state.loader.showLoader = setTimeout(() => {
                    if (state.loader.isLoading) {
                        state.loader.loaderSize = 60;
                        state.loader.opacity = 1;
                    }
                    // state.loader.loaderSize = 60;
                    // state.loader.opacity = 1;
                }, 1500);
            } else {
                if (state.loader.showLoader != null) {
                    clearTimeout(state.loader.showLoader);
                }
                state.loader.loaderSize = 0;
                state.loader.opacity = 0;
            }
        }
        // confirm(state) {
        //     state.isConfirmed = true;
        // }
    },

    actions: {},

    getters: {}
});

export default store;
