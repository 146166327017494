import LoginRepository from "./loginRepository";
import CentersRepository from "./centersRepository";
import UsersRepository from "./usersRepository";
import CategoriesRepository from "./categoriesRepository";
import RoomsRepository from "./roomsRepository";
import RecordingsRepository from "./recordingsRepository";
import EmailsRepository from "./emailsRepository";
import VodsRepository from "./vodsRepository";

const repositories = {
    login: LoginRepository,
    centers: CentersRepository,
    users: UsersRepository,
    categories: CategoriesRepository,
    rooms: RoomsRepository,
    recordings: RecordingsRepository,
    emails: EmailsRepository,
    vods: VodsRepository
};

export const RepositoryFactory = {
    get: (name) => repositories[name]
};
