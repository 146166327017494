<template>
    <footer aria-label="Footer" class="layout-footer" style="margin-top: 3rem">
        <LogoSVG width="25" height="25" />
        <p id="by" class="font-medium ml-2 mt-2" aria-label="Copyright">
            by Bemyvega <span class="font-medium ml-2">v{{ appVersion }}</span>
        </p>
    </footer>
</template>

<script>
import { env_config } from "../config.js";
import LogoSVG from "./components/LogoSVG.vue";

export default {
    name: "AppFooter",
    components: {
        LogoSVG
    },
    data() {
        return {
            appVersion: env_config.version
        };
    },
    computed: {
        darkTheme() {
            return this.$appState.darkTheme;
        }
    },
    methods: {
        footerImage() {
            // return this.$appState.darkTheme ? "images/Logo_139x158.svg" : "images/Logo_139x158.svg";
            return this.$appState.darkTheme ? "images/Logo_148x158N.svg" : "images/Logo_148x158N.svg";
        }
    }
};
</script>
<style></style>
