import api from "./Repository";

const END_POINT = "/categories";

export default {
    // GET
    getCategories() {
        return api.get(`${END_POINT}/`);
    },
    getCategory(categoryId) {
        return api.get(`${END_POINT}/${categoryId}`);
    },
    getRooms(categoryId) {
        return api.get(`${END_POINT}/${categoryId}/rooms/`);
    },

    // POST
    createCategory(payload) {
        return api.post(`${END_POINT}/`, payload);
    },

    // DELETE
    deleteCategory(categoryId) {
        return api.delete(`${END_POINT}/${categoryId}`);
    }
};
