// import Repository from "./Repository";
import api from "./Repository";

const END_POINT = "/login";

export default {
    // GET
    checkToken() {
        return api.get(`${END_POINT}/test-token`);
    },

    // POST
    login(payload) {
        return api.post(`${END_POINT}/access-token`, payload);
    }
};
