<template>
    <main class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden" role="none">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%" role="none">
            <div class="col-12 mt-2 xl:mt-0 text-center" role="none"></div>
            <div class="col-12 xl:col-6" style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, rgba(70, 150, 160, 0.7) 10%, rgba(70, 150, 160, 0) 30%)">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius: 53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0))">
                    <div class="grid flex-column align-items-center" role="none">
                        <!-- <img src="/images/Logo_139x158.svg" alt="Bemyvega logo" class="mb-5" style="width: 81px; height: 60px" /> -->
                        <img src="/images/Logo_148x158N.svg" alt="Bemyvega logo" class="mb-5" style="width: 81px; height: 60px" />
                        <span class="text-blue-900 font-bold text-3xl" :aria-label="$t('accesibility.error.code')">404</span>
                        <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">{{ $t("endpoints.notfound.header") }}</h1>
                        <span class="text-gray-700">{{ $t("endpoints.notfound.message") }}</span>

                        <div class="col-12 flex align-items-center py-5 border-300 border-bottom-1" role="none">
                            <div class="flex justify-content-center align-items-center bg-orange-400 border-round" style="height: 3.5rem; width: 3.5rem">
                                <i class="pi pi-fw pi-question-circle text-50 text-2xl" role="none" aria-hidden="true"></i>
                            </div>
                            <div class="ml-4" role="none">
                                <router-link to="/">
                                    <p class="text-900 lg:text-xl font-medium mb-0">{{ $t("endpoints.notfound.body.faq") }}</p>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-12 flex align-items-center py-5 border-300 border-bottom-1" role="none">
                            <div class="flex justify-content-center align-items-center bg-indigo-400 border-round" style="height: 3.5rem; width: 3.5rem">
                                <i class="pi pi-fw pi-unlock text-50 text-2xl" role="none" aria-hidden="true"></i>
                            </div>
                            <div class="ml-4" role="none">
                                <router-link to="/">
                                    <p class="text-900 lg:text-xl font-medium mb-0">{{ $t("endpoints.notfound.body.permissions") }}</p>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-12 mt-5 text-center" role="none">
                            <i class="pi pi-fw pi-arrow-left text-blue-500 mr-2" style="vertical-align: center" role="none" aria-hidden="true"></i>
                            <router-link to="/" class="text-blue-700">{{ $t("endpoints.notfound.redirect") }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    created() {
        document.title = this.$t("accesibility.title.notfound");
    }
};
</script>
