export default {
  "app": {
    "save": {
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardado"])},
      "unsaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])}
    },
    "menu": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENÚ"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis asignaturas"])},
      "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis salas"])},
      "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis sesiones"])},
      "recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grabaciones"])}
    },
    "topBar": {
      "myprofile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi perfil"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])}
    },
    "recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grabaciones disponibles"])},
    "recordings_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres cambiar el nombre de la grabación?"])},
    "not_recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existen grabaciones en la sesión"])}
  },
  "endpoints": {
    "home": {
      "table": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipos disponibles"])},
        "columns": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo"])},
          "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación"])}
        }
      },
      "cards": {
        "1": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipos disponibles"])}
        },
        "2": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaturas"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis asignaturas"])}
        }
      }
    },
    "categories": {
      "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres borrar la asignatura?"])},
      "tables": {
        "table1": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis asignaturas"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva asignatura"])},
          "dialog": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la asignatura"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
            "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso"])},
            "buttons": {
              "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])}
            }
          },
          "columns": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
            "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso"])}
          }
        },
        "table2": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaturas del centro"])},
          "sort": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por curso"])},
            "options": {
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso mayor"])},
              "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso menor"])}
            }
          },
          "cards": {
            "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso"])}
          }
        }
      }
    },
    "rooms": {
      "card": {
        "header_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiones disponibles"])},
        "header_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiones disponibles"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva sesión"])},
        "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres borrar la sesión?"])},
        "dialog": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la sesión"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "subject": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignatura"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaturas"])}
          },
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
          "buttons": {
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])}
          }
        }
      },
      "table": {
        "header_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis sesiones"])},
        "header_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clases grabadas disponibles"])},
        "columns": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignatura"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])}
        }
      }
    },
    "profile": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi perfil"])},
      "body": {
        "user_mode": {
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesor"])},
          "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estudiante"])}
        },
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
        "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro"])},
        "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre completo"])}
      }
    },
    "register": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro de usuario"])},
      "body": {
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de usuario"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
        "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir contraseña"])},
        "mode": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un modo"])}
        },
        "center": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un centro"])}
        },
        "type": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija uno"])}
        },
        "accept-terms-and-conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto los términos y condiciones"])},
        "terms-and-conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y condiciones"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
        "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ya tienes una cuenta?"])}
      }
    },
    "login": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión"])},
      "body": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
        "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No estás registrado?"])},
        "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste tu contraseña?"])}
      }
    },
    "forgot-password": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar contraseña"])},
      "body": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar email"])}
      },
      "dialog": {
        "success": {
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Enviado!"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un email con tu contraseña ha sido enviado a"])}
        },
        "error": {
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error..."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no está registrado"])}
        }
      }
    },
    "access": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso denegado"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes los permisos necesarios. Por favor, contacte con el administrador."])},
      "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al login"])}
    },
    "error": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ocurrido un error"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los recursos solicitados no están disponibles."])},
      "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al panel de control"])}
    },
    "notfound": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que estás perdido..."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El recurso solicitado no está disponible."])},
      "body": {
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas frecuentes"])},
        "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de permisos"])}
      },
      "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al panel de control"])}
    },
    "pre-stream": {
      "header-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Todo listo para unirte?"])},
      "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu cámara y audio no se verán hasta que no las compartas dentro del streaming."])},
      "join-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unirme ahora"])}
    },
    "code-access": {
      "header-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únete a la experiencia BemyVega"])},
      "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo tienes que meter el código aquí"])},
      "error-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe una sala con el código introducido"])}
    },
    "landing": {
      "topbar": {
        "beginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
        "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])}
      },
      "body": {
        "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por un aprendizaje"])},
        "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sin límites"])},
        "message1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tecnología es la herramienta que nos posibilita el acceso a una formación sin límites, eliminando las barreras que no nos permiten avanzar."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saber más"])},
        "message2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubre todas nuestras funcionalidades"])},
        "cards": {
          "1": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fácil instalación y uso"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encender, conectar el equipo a la red wifi y... listo!"])}
          },
          "2": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramientas de accesibilidad"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom, filtros, cambio de escalas, contrastes...."])}
          },
          "3": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtítulos en tiempo real"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcribimos en tiempo real la información que traslada el ponente a los usuarios"])}
          },
          "4": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento del ponente"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento automático del ponente mediante algoritmos de IA"])}
          },
          "5": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retransmisión simultánea y grabación de sesiones"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retransmite en tiempo real tanto a distancia como presencialmente"])}
          },
          "6": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compatible con diversas plataformas de colaboración"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Teams, Zoom, Google Meet"])}
          },
          "7": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varias vistas disponibles"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección rápida e intuitiva de cualquiera de las tres visualizaciones "])}
          },
          "8": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red local propia"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permite a los usuarios que están en la sala utilizar el equipo sin necesidad de conexión a internet"])}
          },
          "9": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicaciones móviles propias"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Disponibles en Android e iOS"])}
          }
        }
      },
      "footer": {
        "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
        "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redes sociales"])},
        "subheaders": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre nosotros"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para quién"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productos"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])}
        }
      }
    }
  },
  "Appconfig": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temas"])},
    "themes": {
      "theme1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claro"])},
      "theme2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenue"])},
      "theme3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oscuro"])}
    },
    "header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
    "filters": {
      "filter1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monocroma"])},
      "filter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto contraste"])},
      "filter3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negro sobre blanco"])},
      "filter4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blanco sobre negro"])}
    },
    "header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de letra"])},
    "content": {
      "content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original"])},
      "content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legible"])},
      "content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dislexia"])},
      "content4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resaltar enlaces"])},
      "content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No resaltar enlaces"])}
    },
    "header5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TDAH"])},
    "tdah": {
      "tdah1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer enfoque"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño"])},
      "tdah2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guía de lectura"])}
    },
    "header6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baja visión"])},
    "visual": {
      "highlightlinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resaltar enlaces"])},
      "highlighttab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resaltar foco"])}
    },
    "brightness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brillo"])},
    "saturate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturación"])},
    "contrast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraste"])}
  },
  "Media": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reproduciendo"])},
    "button1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIZARRA"])},
    "button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROFESOR"])},
    "button3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRESENTACIÓN"])}
  },
  "accesibility": {
    "buttonStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al inicio de la página"])},
    "menu1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir menú"])},
    "list1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegación en la página actual"])},
    "list2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso de usuario y registro"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducción"])},
    "webBemyvega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a la web de Bemyvega"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funcionalidades"])},
    "landing": {
      "RRSS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redes sociales"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al facebook de Bemyvega"])},
      "youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al youtube de Bemyvega"])},
      "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al linkedin de Bemyvega"])},
      "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al instagram de Bemyvega"])},
      "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al twitter de Bemyvega"])}
    },
    "login": {
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la landing"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
      "repeat-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir contraseña"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
      "goregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al registro"])},
      "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar contraseña"])}
    },
    "register": {
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la landing"])},
      "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
      "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro"])},
      "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión"])},
      "gologin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al inicio de sesión"])},
      "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre completo"])}
    },
    "forgot-password": {
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la página de inicio"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "send-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar email"])}
    },
    "error": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de error"])}
    },
    "dashboard": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de"])},
      "vegas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipos disponibles"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaturas del centro"])},
      "mycategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis asignaturas"])}
    },
    "rooms": {
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar sala"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar el link de la sesión al portapeles"])},
      "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a sala de streaming"])}
    },
    "categories": {
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creador de la asignatura"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaturas disponibles en el centro"])},
      "gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de mis asignaturas"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar asignatura"])}
    },
    "media": {
      "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reproductor"])},
      "player1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualización pizarra"])},
      "player2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualización profesor"])},
      "player3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualización escena"])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
      "activeUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos usuarios conectados"])},
      "streaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reproductor en streaming"])},
      "deferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reproductor en diferido"])},
      "recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grabaciones disponibles"])}
    },
    "recordings": {
      "button1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reproducir sesión"])},
      "button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar sesión"])},
      "button3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar nombre"])},
      "button4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar grabación"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones disponibles"])}
    },
    "controls": {
      "controls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controles del reproductor"])},
      "buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controles de las visualizaciones"])},
      "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
      "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
      "mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silenciar"])},
      "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonido"])},
      "sonido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control deslizante de audio"])},
      "maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximizar pantalla"])},
      "minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimizar pantalla"])},
      "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comenzar grabación"])},
      "norecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parar grabación"])},
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar visualización izquierda"])},
      "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar visualización derecha"])},
      "timeIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo actual"])},
      "timeOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control deslizante de tiempo"])}
    },
    "topbar": {
      "toolbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barra de herramientas"])},
      "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega logo"])},
      "sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir/cerrar menu lateral"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes de usuario"])}
    },
    "appconfig": {
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir panel de accesibilidad"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar panel de accesibilidad"])},
      "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disminuir fuente"])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumentar fuente"])},
      "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar filtros"])}
    },
    "title": {
      "landing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega:inicio"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro de usuario"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso denegado"])},
      "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurso no encontrado"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega: Inicio"])},
      "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Bemyvega: Salas"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega: Categorías"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi perfil"])},
      "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega: Reproductor en Streaming"])},
      "deferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega: Reproductor en Diferido"])},
      "recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega: Grabaciones"])}
    }
  }
}