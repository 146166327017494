<template>
    <div :class="containerClass" role="none" @click="onWrapperClick">
        <div id="tdah-top" style="display: none"></div>
        <div id="guide" style="display: none"></div>
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div v-if="userMode == 'teacher'" class="layout-sidebar" role="none" @click="onSidebarClick">
            <AppMenu id="app-menu" :model="menu" @menuitem-click="onMenuItemClick" />
        </div>

        <!-- <div class="layout-main-container" :style="{ padding: ['/demo/vod', '/demo/streaming', '/demo/bidirectional', '/stream/WtwELf3jZHM3iQeuDwJ5uq'].includes($route.path) ? 0 : '', margin: ['/demo/vod', '/demo/streaming', '/demo/bidirectional', '/stream/WtwELf3jZHM3iQeuDwJ5uq'].includes($route.path) ? 0 : '' }"> -->
        <div class="layout-main-container" :style="{ padding: $route.path.includes('/stream') || $route.path.includes('/demo') || $route.path.includes('/vod') ? 0 : '', margin: $route.path.includes('/stream') || $route.path.includes('/demo') || $route.path.includes('/vod') ? 0 : '' }">
            <div class="layout-main">
                <router-view />
            </div>
            <AppFooter />
        </div>

        <AppConfig :layout-mode="layoutMode" @layout-change="onLayoutChange" @change-theme="changeTheme" />
        <transition name="layout-mask">
            <div v-if="mobileMenuActive" class="layout-mask p-component-overlay"></div>
        </transition>
        <div id="tdah-bottom" style="display: none"></div>
    </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";

import { env_config } from "../config";
import { isLoggedIn, getUserInfo } from "./utils/auth.js";

export default {
    components: {
        AppTopBar: AppTopBar,
        AppMenu: AppMenu,
        AppConfig: AppConfig,
        AppFooter: AppFooter
    },
    emits: ["change-theme"],
    data() {
        return {
            userMode: null,
            layoutMode: "static",
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu: []
        };
    },
    computed: {
        containerClass() {
            return [
                "layout-wrapper",
                {
                    "layout-overlay": this.layoutMode === "overlay",
                    "layout-static": this.layoutMode === "static",
                    "layout-static-sidebar-inactive": this.staticMenuInactive && this.layoutMode === "static",
                    "layout-overlay-sidebar-active": this.overlayMenuActive && this.layoutMode === "overlay",
                    "layout-mobile-sidebar-active": this.mobileMenuActive,
                    "p-input-filled": this.$primevue.config.inputStyle === "filled",
                    "p-ripple-disabled": this.$primevue.config.ripple === false
                }
            ];
        },
        logo() {
            return this.$appState.darkTheme ? "images/logo.svg" : "images/logo.svg";
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
        "$i18n.locale": function () {
            this.onLangChange();
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive) this.addClass(document.body, "body-overflow-hidden");
        else this.removeClass(document.body, "body-overflow-hidden");
    },
    created() {
        this.$i18n.locale = localStorage.getItem("lang");
        this.onLangChange();

        let { user_mode } = getUserInfo();
        this.userMode = user_mode;
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === "overlay") {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                } else if (this.layoutMode === "static") {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            } else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList) element.classList.add(className);
            else element.className += " " + className;
        },
        removeClass(element, className) {
            if (element.classList) element.classList.remove(className);
            else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === "static") return !this.staticMenuInactive;
                else if (this.layoutMode === "overlay") return this.overlayMenuActive;
            }

            return true;
        },
        changeTheme(event) {
            this.$emit("change-theme", event);
        },
        onLangChange() {
            if (isLoggedIn()) {
                if (env_config.RECORDINGS) {
                    this.menu = [
                        // {
                        //     icon: "pi pimenu pi-fw pi-sitemap",
                        //     items: [
                        //         {
                        //             label: this.$t("app.menu.categories"),
                        //             icon: "pi pi-fw pi-clone",
                        //             role: "recordings",
                        //             to: "/categories"
                        //         }
                        //     ]
                        // },
                        { icon: "pi pimenu pi-fw pi-sitemap", items: [{ label: this.$t("app.menu.rooms"), icon: "pi pi-fw pi-table", role: "rooms", to: "/rooms" }] },
                        { icon: "pi pimenu pi-fw pi-sitemap", items: [{ label: this.$t("app.menu.sessions"), icon: "pi pi-fw pi-table", role: "sessions", to: "/sessions" }] },
                        // { icon: "pi pimenu pi-fw pi-sitemap", items: [{ label: this.$t("app.menu.sessions"), icon: "pi pi-fw pi-table", role: "sessions", to: "/sessions" }] },
                        { icon: "pi pimenu pi-fw pi-sitemap", items: [{ label: this.$t("app.menu.recordings"), icon: "pi pi-fw pi-folder-open", role: "recordings", to: "/recordings" }] }
                    ];
                } else {
                    this.menu = [
                        // {
                        //     icon: "pi pimenu pi-fw pi-sitemap",
                        //     items: [
                        //         {
                        //             label: this.$t("app.menu.categories"),
                        //             icon: "pi pi-fw pi-clone",
                        //             to: "/categories"
                        //         }
                        //     ]
                        // },
                        { icon: "pi pimenu pi-fw pi-sitemap", items: [{ label: this.$t("app.menu.rooms"), icon: "pi pi-fw pi-table", role: "none", to: "/rooms" }] },
                        { icon: "pi pimenu pi-fw pi-sitemap", items: [{ label: this.$t("app.menu.sessions"), icon: "pi pi-fw pi-table", role: "sessions", to: "/sessions" }] }
                        // { icon: "pi pimenu pi-fw pi-sitemap", items: [{ label: this.$t("app.menu.recordings"), icon: "pi pi-fw pi-folder-open", role: "none", to: "/overlay" }] }
                    ];
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "./App.scss";

.pimenu {
    font-size: 1.5rem !important;
}
@font-face {
    font-family: "OpenDyslexic";
    src: local("OpenDyslexic"), url(./fonts/OpenDyslexic-Regular.otf) format("truetype");
}
@font-face {
    font-family: "Aller_Lt";
    src: local("Aller_Lt"), url(./fonts/Aller_Lt.ttf) format("truetype");
}
@font-face {
    font-family: "OpenSans-Regular";
    src: local("OpenSans-Regular"), url(./fonts/OpenSans-Regular.ttf) format("truetype");
}

#tdah-top {
    position: absolute;
    width: 100%;
    opacity: 0.75;
    background: grey;
    border: 2px solid black;
    filter: brightness(30%);
    z-index: 1000;
    top: 0rem;
}

#tdah-bottom {
    position: fixed;
    width: 100%;
    opacity: 0.75;
    background: grey;
    border: 2px solid black;
    filter: brightness(30%);
    z-index: 1000;
    bottom: 0rem;
}
#guide {
    position: absolute;
    width: 100%;
    opacity: 1;
    background: red;
    border: 2px solid red;
    z-index: 1000;
}
</style>
