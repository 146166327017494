import { stringToBoolean } from "./src/utils/utils";

const env_config = {
    env_mode: process.env.NODE_ENV || "development",
    WEBRTC_ROOT: process.env.VUE_APP_WEBRTC_ROOT || "ome-test-2.bemyvega.dev",
    locale: process.env.VUE_APP_LOCALE || "en",
    admin_username: process.env.VUE_APP_ADMIN_USERNAME || "antonio@bemyvega.com",
    admin_password: process.env.VUE_APP_ADMIN_PASSWORD || "antonio",
    version: process.env.VUE_APP_VERSION || "testing",
    client_name: process.env.VUE_APP_CLIENT_NAME || "dev",
    API_BASE_DOMAIN: process.env.VUE_APP_API_BASE_DOMAIN || "http://127.0.0.1:8000",
    // RECORDINGS: stringToBoolean(process.env.VUE_APP_RECORDINGS) || true,
    RECORDINGS: process.env.VUE_APP_RECORDINGS ? stringToBoolean(process.env.VUE_APP_RECORDINGS) : true,
    center: process.env.VUE_APP_CENTER || "Bemyvega",
    // DEMO_USER_BUTTON: stringToBoolean(process.env.VUE_APP_SHOW_DEMO_USER_BUTTON) || false
    DEMO_USER_BUTTON: process.env.VUE_APP_SHOW_DEMO_USER_BUTTON ? stringToBoolean(process.env.VUE_APP_SHOW_DEMO_USER_BUTTON) : false
};

export { env_config };
