import api from "./Repository";

const END_POINT = "/users";

export default {
    // GET
    getUsers(params) {
        return api.get(`${END_POINT}/`, { params });
    },
    getUser(userId) {
        return api.get(`${END_POINT}/${userId}`);
    },
    getCategories(userId) {
        return api.get(`${END_POINT}/${userId}/categories/`);
    },
    getRooms(userId) {
        return api.get(`${END_POINT}/${userId}/rooms/`);
    },
    getTemporalUser(action = "") {
        return api.get(`${END_POINT}/user/temp?action=${action}`);
    },

    // POST
    createUser(payload) {
        return api.post(`${END_POINT}/`, payload);
    },

    // DELETE
    deleteUser(userId) {
        return api.delete(`${END_POINT}/${userId}`);
    }
};
