import jwt_decode from "jwt-decode";

const AUTH_TOKEN_KEY = "authToken";

export function setAuthToken(token) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);

    // Set the cookie with the domain attribute
    document.cookie = `authToken=${token}; domain=bemyvega.dev; path=/`;
}

export function getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function clearAuthToken() {
    localStorage.removeItem(AUTH_TOKEN_KEY);

    // Set the cookie expiration to a past date
    document.cookie = "authToken=; domain=bemyvega.dev; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
}

export function isLoggedIn() {
    let authToken = getAuthToken();
    return !!authToken;
}

export function logoutUser() {
    clearAuthToken();
}

export function getUserInfo() {
    // Decode token
    let decoded = jwt_decode(getAuthToken());

    let userInfo = {
        user_id: decoded["id"],
        username: decoded["sub"],
        email: decoded["email"],
        center_id: decoded["center_id"],
        user_mode: decoded["mode"],
        logo: decoded["logo"]
    };

    return userInfo;
}
