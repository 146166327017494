<template>
    <Landing v-if="$route.path == '/ '" @change-theme="changeTheme" />
    <Login v-else-if="$route.path === '/login'" />
    <Register v-else-if="$route.path === '/register'" />
    <ForgotPassword v-else-if="$route.path === '/forgot-password'" />
    <Error v-else-if="$route.path === '/error'" />
    <NotFound v-else-if="$route.path === '/notfound'" />
    <Access v-else-if="$route.path === '/access'" />
    <App v-else @change-theme="changeTheme" />
</template>

<script>
import EventBus from "./AppEventBus";
import App from "./App";
import Landing from "./pages/LandingDemo";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";

export default {
    components: {
        App,
        Landing,
        Login,
        Register,
        ForgotPassword,
        Error,
        NotFound,
        Access
    },
    methods: {
        changeTheme(event) {
            let themeElement = document.getElementById("theme-link");
            themeElement.setAttribute("href", themeElement.getAttribute("href").replace(this.$appState.theme, event.theme));
            this.$appState.theme = event.theme;
            this.$appState.darkTheme = event.dark;
            EventBus.emit("change-theme", event);

            if (event.theme.startsWith("md")) {
                this.$primevue.config.ripple = true;
            }
        }
    }
};
</script>
