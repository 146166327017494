export function passwordValidator(value) {
    var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
    return mediumRegex.test(value);
}

export function capitalizeFirstLetter(value) {
    const str = value;
    const cap_str = str.charAt(0).toUpperCase() + str.slice(1);
    return cap_str;
}

export function getId(self_field) {
    let arr = self_field.split("/");
    return arr[arr.length - 1];
}

export function getType(self_field) {
    let arr = self_field.split("/");
    return arr[1];
}

export function stringToBoolean(stringValue) {
    switch (stringValue.toLowerCase().trim()) {
        case "true":
        case "yes":
        case "1":
            return true;

        case "false":
        case "no":
        case "0":
            return false;

        default:
            return false;
    }
}

export function getClientOS() {
    const agent = window.navigator.userAgent.toLowerCase();
    /* prettier-ignore */
    const os =
    agent.indexOf('windows') > -1 ? 'Windows'
      : agent.indexOf('mac') > -1 ? 'MacOS'
      : agent.indexOf('linux') > -1 ? 'Linux'
      : agent.indexOf('x11') > -1 && !(agent.search("linux")) ? 'UNIX'
      : 'Unknown OS';

    return os;
}

export function getClientBrowser() {
    const agent = window.navigator.userAgent.toLowerCase();
    /* prettier-ignore */
    const browser =
    agent.indexOf('edge') > -1 ? 'Edge'
      : agent.indexOf('edg') > -1 ? 'Chromium based edge'
      : agent.indexOf('opr') > -1 && window.opr ? 'Opera'
      : agent.indexOf('chrome') > -1 && window.chrome ? 'Chrome'
      : agent.indexOf('trident') > -1 ? 'IE'
      : agent.indexOf('firefox') > -1 ? 'Firefox'
      : agent.indexOf('safari') > -1 ? 'Safari'
      : 'Unknown browser';

    return browser;
}

export function updateFilter(type, value) {
    const currentValue = document.documentElement.style.filter;
    const regex = new RegExp(`${type}\\([^)]*\\)`);
    let newValue = `${type}(${value}%)`;
    if (currentValue.includes(type)) {
        newValue = currentValue.replace(regex, newValue);
        document.documentElement.style.filter = newValue;
    } else {
        document.documentElement.style.filter = `${currentValue} ${newValue}`;
    }
}

export async function downloadVideo(url) {
    window.open(url);
}
