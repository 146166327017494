import api from "./Repository";

const END_POINT = "/emails";

export default {
    // POST
    forgotPassword(payload) {
        return api.post(`${END_POINT}/forgot-password`, payload);
    }
};
