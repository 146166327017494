<template>
    <div></div>
    <!-- <nav id="layout-config" :class="containerClass" aria-label="Controles de accesibilidad">
        <button id="layout-config-button" class="layout-config-button" :aria-label="$t('accesibility.appconfig.open')" @click="toggleConfigurator">
            <LogoSVG color="#ffffff" viewBox="-19 0 179 158" style="display: block" />
        </button>

        <div class="layout-config-content" tabindex="-1">
            <div>
                <Button class="p-button-danger layout-config-close p-button-rounded p-button-text" icon="pi pi-times" :style="{ 'z-index': 1 }" :aria-label="$t('accesibility.appconfig.close')" @click="hideConfigurator"></Button>
                <InputSwitch v-model="checked" style="float: right; margin-right: 1.5rem; margin-top: -0.5rem" :aria-checked="checked" aria-describedby="saved" @click="saveConfig()" />
                <label v-if="checked" id="saved" style="float: right; margin-right: 0.8rem; margin-top: -0.3rem">{{ $t("app.save.saved") }}</label>
                <label v-if="!checked" id="saved" style="float: right; margin-right: 0.8rem; margin-top: -0.3rem">{{ $t("app.save.unsaved") }}</label>
            </div>

            <h3 class="mt-4">{{ $t("Appconfig.header1") }}</h3>
            <div class="config-scale">
                <Button icon="pi pi-minus" class="p-button-text" :disabled="scale === scales[0]" :aria-label="$t('accesibility.appconfig.min')" @click="decrementScale()" />
                <i v-for="s of scales" :key="s" class="pi pi-circle-on" :class="{ 'scale-active': s === scale }" role="none" aria-hidden="true" />
                <Button icon="pi pi-plus" class="p-button-text" :disabled="scale === scales[scales.length - 1]" :aria-label="$t('accesibility.appconfig.max')" @click="incrementScale()" />
            </div>

            <hr class="line" role="none" aria-hidden="true" />
            <h3 id="h-themes" class="mt-0">{{ $t("Appconfig.header2") }}</h3>
            <div class="grid mt-2" role="radiogroup" aria-labelledby="h-themes">
                <RadioButton id="option1" v-model="radioValue" class="ml-2" name="p-light" value="Claro" aria-label="p-light" aria-checked="true" tabindex="0" @click="changeTheme($event, 'bootstrap4-light-blue', false)" />
                <label id="p-light" class="title ml-1" for="option1">{{ $t("Appconfig.themes.theme1") }}</label>

                <RadioButton id="option2" v-model="radioValue" class="ml-2" name="p-blue" value="Tenue" aria-label="p-blue" aria-checked="false" tabindex="0" @click="changeTheme($event, 'bootstrap4-dark-blue', true)" />
                <label id="p-blue" class="title ml-1" for="option2">{{ $t("Appconfig.themes.theme2") }}</label>

                <RadioButton id="option3" v-model="radioValue" class="ml-2" name="p-dark" value="Oscuro" aria-label="p-dark" aria-checked="false" tabindex="0" @click="changeTheme($event, 'arya-blue', true)" />
                <label id="p-dark" class="title ml-1" for="option3">{{ $t("Appconfig.themes.theme3") }}</label>
            </div>

            <hr class="line" role="none" aria-hidden="true" />
            <div class="grid free-themes col-12 text-center mb-4">
                <h3 class="mt-0">{{ $t("Appconfig.header3") }}</h3>
                <button id="filter1" class="p-button-info buttonAcc title" aria-pressed="false" @click="filter1()">{{ $t("Appconfig.filters.filter1") }}</button>
                <button id="filter2" class="p-button-info buttonAcc title" aria-pressed="false" @click="filter2()">{{ $t("Appconfig.filters.filter2") }}</button>
                <button id="filter3" class="p-button-info buttonAcc title" aria-pressed="false" @click="filter3()">{{ $t("Appconfig.filters.filter3") }}</button>
                <button id="filter4" class="p-button-info buttonAcc title" aria-pressed="false" @click="filter4()">{{ $t("Appconfig.filters.filter4") }}</button>
            </div>

            <label id="p-brightness">{{ $t("Appconfig.brightness") }}</label>
            <Slider id="brightness" v-model="sliderLevelBrightness" class="mb-2 mt-2" role="slider" aria-labelledby="p-brightness" aria-valuemin="30" aria-valuemax="200" :aria-valuenow="sliderLevelBrightness" :min="30" :max="200" @change="changeBrightness(sliderLevelBrightness)" />
            <label id="p-saturate">{{ $t("Appconfig.saturate") }}</label>
            <Slider id="saturate" v-model="sliderLevelSaturate" class="mb-2 mt-2" role="slider" aria-labelledby="p-saturate" aria-valuemin="0" aria-valuemax="200" :aria-valuenow="sliderLevelSaturate" :min="0" :max="200" @change="changeSaturation(sliderLevelSaturate)" />
            <label id="p-contrast">{{ $t("Appconfig.contrast") }}</label>
            <Slider id="contrast" v-model="sliderLevelContrast" class="mb-2 mt-2" role="slider" aria-labelledby="p-contrast" aria-valuemin="30" aria-valuemax="250" :aria-valuenow="sliderLevelContrast" :min="30" :max="250" @change="changeContrast(sliderLevelContrast)" />

            <div class="grid free-themes col-12 text-center">
                <button id="reset" class="p-button-info reset title mr-auto ml-auto" type="button" :aria-label="$t('accesibility.appconfig.quit')" @click="resetfilters()">Reset</button>
                <p class="mr-auto ml-auto mt-4">Color <ColorPicker v-model="color" :inline="false" @change="changeColor(color)" /></p>
            </div>

            <hr class="line" role="none" aria-hidden="true" />
            <div class="grid free-themes col-12 text-center">
                <h3>{{ $t("Appconfig.header4") }}</h3>
                <button id="fn1" class="p-button-info buttonAcc title activebutton" aria-pressed="true" style="font-family: Arial !important" @click="fontSelector()">{{ $t("Appconfig.content.content1") }}</button>
                <button id="fn3" class="p-button-info buttonAcc title" aria-pressed="false" @click="fontSelector3()">{{ $t("Appconfig.content.content3") }}</button>
            </div>

            <hr class="line" role="none" aria-hidden="true" />
            <div class="grid free-themes col-12 text-center">
                <h3>{{ $t("Appconfig.header5") }}</h3>
                <button id="tdah1" class="p-button-info buttonAcc title" aria-pressed="false" @click="readFocus()">{{ $t("Appconfig.tdah.tdah1") }}</button>
                <div class="grid m-auto">
                    <Button icon="pi pi-minus" :disabled="focus_size === focus_sizes[0]" class="title p-button-rounded p-button-text" @click="decreaseFocus()" />
                    <p class="mt-2">{{ $t("Appconfig.tdah.size") }}: {{ focus_size }} px</p>
                    <Button icon="pi pi-plus" :disabled="focus_size === focus_sizes[focus_sizes.length - 1]" class="p-button-rounded p-button-text" @click="increaseFocus()" />
                </div>
                <button id="tdah2" class="p-button-info buttonAcc title" aria-pressed="false" @click="readGuide()">{{ $t("Appconfig.tdah.tdah2") }}</button>
            </div>

            <hr class="line" role="none" aria-hidden="true" />
            <div class="grid free-themes col-12 text-center">
                <h3>{{ $t("Appconfig.header6") }}</h3>
                <button id="tab" type="button" class="p-button-info buttonAcc title" :aria-pressed="hightab" @click="changeFocus()">{{ $t("Appconfig.visual.highlighttab") }}</button>
            </div>
        </div>
    </nav> -->
</template>

<script>
import { env_config } from "../config.js";
import { updateFilter } from "./utils/utils.js";
import { centers } from "./utils/centers.js";
// import LogoSVG from "./components/LogoSVG.vue";

export default {
    components: {
        // LogoSVG
    },
    props: {
        layoutMode: {
            type: String,
            default: null
        }
    },
    emits: ["change-theme"],
    data() {
        return {
            active: false,
            d_layoutMode: this.layoutMode,
            scale: 16,
            scales: [14, 15, 16, 17, 18, 19, 20],
            radioValue: "Claro",
            showReadable: true,
            showDyslexia: false,
            showOriginal: false,
            sliderLevelBrightness: 100,
            sliderLevelSaturate: 100,
            sliderLevelContrast: 100,
            font: 0,
            filter: 0,
            focus: false,
            guide: false,
            checked: true,
            color: "007680",
            // highlight: false,
            hightab: false,
            focus_size: 60,
            focus_sizes: [20, 40, 60, 80, 100, 120, 140, 160]
        };
    },
    computed: {
        containerClass() {
            return ["layout-config", { "layout-config-active": this.active }];
        },
        rippleActive() {
            return this.$primevue.config.ripple;
        },
        inputStyle() {
            return this.$appState.inputStyle;
        }
    },
    watch: {
        $route() {
            if (this.active) {
                this.active = false;
                this.unbindOutsideClickListener();
            }
        },
        layoutMode(newValue) {
            this.d_layoutMode = newValue;
        }
    },
    outsideClickListener: null,
    created() {
        document.documentElement.style.fontSize = this.scale + "px";
        document.documentElement.style.setProperty("--primary-color", centers[env_config.center]);
        // if (env_config.center == "Bemyvega") {
        //     document.documentElement.style.setProperty("--primary-color", "#007680");
        // } else if (env_config.center == "UCAM") {
        //     document.documentElement.style.setProperty("--primary-color", "#004378");
        // } else if (env_config.center == "UCO") {
        //     document.documentElement.style.setProperty("--primary-color", "#131a59");
        // } else if (env_config.center == "UPCT") {
        //     document.documentElement.style.setProperty("--primary-color", "#78B2E0");
        // } else if (env_config.center == "IndustrialesUPCT") {
        //     document.documentElement.style.setProperty("--primary-color", "#A5272A");
        // }
    },
    mounted() {
        this.checkCookie();
        this.checked = true;
    },
    methods: {
        changeColor(value) {
            this.color = value;
            document.documentElement.style.setProperty("--primary-color", "#" + this.color);
        },
        saveConfig() {
            let config = [this.scale, this.radioValue, this.filter, this.sliderLevelBrightness, this.sliderLevelSaturate, this.sliderLevelContrast, this.font, this.focus, this.guide, this.color, this.hightab];
            this.setCookie("config", config, 100);
            if (this.checked == false) {
                this.showSuccess("Configuración guardada");
            }
        },
        showSuccess(detail) {
            this.$toast.add({ severity: "success", summary: "Success", detail: detail, life: 3000 });
        },
        setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; " + expires;
            //console.log(cname + "=" + cvalue);
        },
        getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(";");
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        checkCookie() {
            let config = this.getCookie("config");
            if (config != "") {
                let split = config.split(",");

                this.scale = parseInt(split[0]);
                this.applyScale();

                this.radioValue = split[1];
                if (this.radioValue == "Claro") {
                    this.changeTheme("click", "bootstrap4-light-blue", false);
                } else if (this.radioValue == "Tenue") {
                    this.changeTheme("click", "bootstrap4-dark-blue", true);
                } else {
                    this.changeTheme("click", "arya-blue", true);
                }
                this.sliderLevelBrightness = split[3];
                this.changeBrightness(this.sliderLevelBrightness);

                this.sliderLevelSaturate = split[4];
                this.changeSaturation(this.sliderLevelSaturate);

                this.sliderLevelContrast = split[5];
                this.changeContrast(this.sliderLevelContrast);

                if (split[2] == "1") {
                    this.filter1();
                } else if (split[2] == "2") {
                    this.filter2();
                } else if (split[2] == "3") {
                    this.filter3();
                } else if (split[2] == "4") {
                    this.filter4();
                }

                if (split[6] == "1") {
                    this.fontSelector3();
                }

                if (split[7] == "true") {
                    this.readFocus();
                }
                if (split[8] == "true") {
                    this.readGuide();
                }

                this.color = split[9];
                this.changeColor(this.color);

                if (split[10] == "true") {
                    console.log("El split 10 es true");

                    this.hightab = false;
                    this.changeFocus();
                }
                //console.log(split);
                // if (split[11] == "true") {
                //     this.highlightLinks();
                // }
            }
        },
        toggleConfigurator(event) {
            this.active = !this.active;
            event.preventDefault();

            if (this.active) this.bindOutsideClickListener();
            else this.unbindOutsideClickListener();
        },
        hideConfigurator(event) {
            this.active = false;
            this.unbindOutsideClickListener();
            event.preventDefault();
        },

        bindOutsideClickListener() {
            if (!this.outsideClickListener) {
                this.outsideClickListener = (event) => {
                    if (this.active && this.isOutsideClicked(event)) {
                        this.active = false;
                    }
                };
                document.addEventListener("click", this.outsideClickListener);
            }
        },
        unbindOutsideClickListener() {
            if (this.outsideClickListener) {
                document.removeEventListener("click", this.outsideClickListener);
                this.outsideClickListener = null;
            }
        },
        isOutsideClicked(event) {
            return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target));
        },
        decrementScale() {
            this.scale--;
            this.applyScale();
        },
        incrementScale() {
            this.scale++;
            this.applyScale();
        },
        applyScale() {
            this.checked = false;
            document.documentElement.style.fontSize = this.scale + "px";
        },
        changeTheme(event, theme, dark) {
            this.checked = false;
            this.$emit("change-theme", { theme, dark });
        },
        filter1() {
            this.checked = false;
            this.resetfilters();
            this.filter = 1;
            document.documentElement.style.filter = "grayscale(100%)";
            document.getElementById("filter1").classList.add("activebutton");

            document.getElementById("filter1").setAttribute("aria-pressed", "true");
            document.getElementById("filter2").setAttribute("aria-pressed", "false");
            document.getElementById("filter3").setAttribute("aria-pressed", "false");
            document.getElementById("filter4").setAttribute("aria-pressed", "false");
        },
        filter2() {
            this.checked = false;
            if (this.radioValue == "Claro") {
                this.resetfilters();
                this.filter = 2;
                document.documentElement.style.filter = "invert(100%)";
                document.getElementById("filter2").classList.add("activebutton");
                document.getElementById("by").classList.add("resetbugN");

                document.getElementById("filter1").setAttribute("aria-pressed", "false");
                document.getElementById("filter2").setAttribute("aria-pressed", "true");
                document.getElementById("filter3").setAttribute("aria-pressed", "false");
                document.getElementById("filter4").setAttribute("aria-pressed", "false");
            } else {
                this.resetfilters();
                this.filter = 2;
                document.documentElement.style.filter = "invert(100%)";
                document.getElementById("filter2").classList.add("activebutton");
                document.getElementById("by").classList.add("resetbugB");

                document.getElementById("filter1").setAttribute("aria-pressed", "false");
                document.getElementById("filter2").setAttribute("aria-pressed", "true");
                document.getElementById("filter3").setAttribute("aria-pressed", "false");
                document.getElementById("filter4").setAttribute("aria-pressed", "false");
            }
        },
        filter3() {
            this.checked = false;
            if (this.radioValue == "Claro") {
                this.resetfilters();
                this.filter = 3;
                this.sliderLevelBrightness = 70;
                this.sliderLevelSaturate = 0;
                this.sliderLevelContrast = 250;
                document.documentElement.style.filter = "brightness(70%) contrast(250%) saturate(0%)";
                document.getElementById("filter3").classList.add("activebuttonbug");
                document.getElementById("by").classList.add("resetbugN");

                document.getElementById("filter1").setAttribute("aria-pressed", "false");
                document.getElementById("filter2").setAttribute("aria-pressed", "false");
                document.getElementById("filter3").setAttribute("aria-pressed", "true");
                document.getElementById("filter4").setAttribute("aria-pressed", "false");
            } else {
                this.resetfilters();
                this.filter = 3;
                this.sliderLevelBrightness = 70;
                this.sliderLevelSaturate = 0;
                this.sliderLevelContrast = 200;
                document.documentElement.style.filter = "brightness(70%) contrast(200%) saturate(0%)";
                document.getElementById("filter3").classList.add("activebuttonbug");

                document.getElementById("filter1").setAttribute("aria-pressed", "false");
                document.getElementById("filter2").setAttribute("aria-pressed", "false");
                document.getElementById("filter3").setAttribute("aria-pressed", "true");
                document.getElementById("filter4").setAttribute("aria-pressed", "false");
            }
        },
        filter4() {
            this.checked = false;
            //console.log(this.radiovalue);
            if (this.radioValue == "Claro") {
                this.resetfilters();
                this.filter = 4;
                this.sliderLevelBrightness = 70;
                this.sliderLevelSaturate = 0;
                this.sliderLevelContrast = 250;
                document.documentElement.style.filter = "brightness(70%)  contrast(250%) saturate(0%) invert(100%)";
                document.getElementById("filter4").classList.add("activebuttonbug");
                document.getElementById("by").classList.add("resetbugN");

                document.getElementById("filter1").setAttribute("aria-pressed", "false");
                document.getElementById("filter2").setAttribute("aria-pressed", "false");
                document.getElementById("filter3").setAttribute("aria-pressed", "false");
                document.getElementById("filter4").setAttribute("aria-pressed", "true");
            } else {
                this.resetfilters();
                this.filter = 4;
                this.sliderLevelBrightness = 70;
                this.sliderLevelSaturate = 0;
                this.sliderLevelContrast = 200;
                document.documentElement.style.filter = "brightness(70%)  contrast(200%) saturate(0%) invert(100%)";
                document.getElementById("filter4").classList.add("activebuttonbug");
                document.getElementById("by").classList.add("resetbugB");

                document.getElementById("filter1").setAttribute("aria-pressed", "false");
                document.getElementById("filter2").setAttribute("aria-pressed", "false");
                document.getElementById("filter3").setAttribute("aria-pressed", "false");
                document.getElementById("filter4").setAttribute("aria-pressed", "true");
            }
        },
        resetfilters() {
            this.color = "007680";
            this.changeColor(this.color);
            this.checked = false;
            this.filter = 0;
            this.sliderLevelBrightness = 100;
            this.sliderLevelSaturate = 100;
            this.sliderLevelContrast = 100;
            // document.documentElement.style.filter = "none";
            document.documentElement.style.filter = "";
            document.getElementById("filter1").classList.remove("activebutton");
            document.getElementById("filter2").classList.remove("activebutton");
            document.getElementById("filter3").classList.remove("activebuttonbug");
            document.getElementById("filter4").classList.remove("activebuttonbug");
            document.getElementById("by").classList.remove("resetbugB");
            document.getElementById("by").classList.remove("resetbugN");
        },
        fontSelector() {
            this.checked = false;
            this.font = 0;
            document.getElementById("fn1").classList.add("activebutton");
            document.getElementById("fn3").classList.remove("activebutton");
            // document.documentElement.style.setProperty("--font-family", "Arial, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol");
            document.documentElement.style.setProperty("--font-family", "Segoe UI, Roboto, Helvetica, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol");

            let font2 = document.getElementsByTagName("button");
            for (let i = 0; i < font2.length; i++) {
                font2[i].style.fontFamily = "Arial";
            }
            document.getElementById("fn1").setAttribute("aria-pressed", "true");
            document.getElementById("fn3").setAttribute("aria-pressed", "false");
            this.unbindOutsideClickListener();
        },
        fontSelector3() {
            this.checked = false;
            this.font = 1;
            document.getElementById("fn1").classList.remove("activebutton");
            document.getElementById("fn3").classList.add("activebutton");
            document.documentElement.style.setProperty("--font-family", "OpenDyslexic, sans-serif, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol");
            let font2 = document.getElementsByTagName("button");
            for (let i = 0; i < font2.length; i++) {
                font2[i].style.fontFamily = "OpenDyslexic";
            }
            document.getElementById("fn1").setAttribute("aria-pressed", "false");
            document.getElementById("fn3").setAttribute("aria-pressed", "true");
            this.unbindOutsideClickListener();
        },
        readFocus() {
            this.checked = false;
            let aux = this.focus_size;
            document.addEventListener("mousemove", function (e) {
                let top = document.getElementById("tdah-top");
                let bottom = document.getElementById("tdah-bottom");
                top.style.height = e.clientY - aux + window.scrollY + "px";
                bottom.style.top = e.clientY + aux + "px";

                let guide = document.getElementById("guide");
                guide.style.marginTop = e.clientY + window.scrollY + 2 + "px";
            });
            if (this.focus == false) {
                this.focus = true;
                document.getElementById("tdah-top").style.display = "inline";
                document.getElementById("tdah-bottom").style.display = "inline";
                document.getElementById("tdah1").classList.add("activebutton");
                document.getElementById("tdah1").ariaPressed = true;
            } else {
                this.focus = false;
                document.getElementById("tdah-top").style.display = "none";
                document.getElementById("tdah-bottom").style.display = "none";
                document.getElementById("tdah1").classList.remove("activebutton");
                document.getElementById("tdah1").ariaPressed = false;
            }
        },
        increaseFocus() {
            let aux = this.focus_size + 20;
            this.focus_size = aux;
            this.focus = false;
            this.readFocus();
        },
        decreaseFocus() {
            let aux = this.focus_size - 20;
            this.focus_size = aux;
            this.focus = false;
            this.readFocus();
        },
        readGuide() {
            this.checked = false;
            document.addEventListener("mousemove", function (e) {
                let guide = document.getElementById("guide");
                guide.style.marginTop = e.clientY + window.scrollY + 2 + "px";
            });
            if (this.guide == false) {
                this.guide = true;
                document.getElementById("guide").style.display = "inline";
                document.getElementById("tdah2").classList.add("activebutton");
                document.getElementById("tdah2").ariaPressed = true;
            } else {
                this.guide = false;
                document.getElementById("guide").style.display = "none";
                document.getElementById("tdah2").classList.remove("activebutton");
                document.getElementById("tdah2").ariaPressed = false;
            }
        },
        changeBrightness(value) {
            this.checked = false;
            updateFilter("brightness", value);
        },
        changeSaturation(value) {
            this.checked = false;
            updateFilter("saturate", value);
        },
        changeContrast(value) {
            this.checked = false;
            updateFilter("contrast", value);
        },

        // highlightLinks() {
        //     if (this.highlight == true) {
        //         this.highlight = false;
        //         // document.getElementById("highlight").innerHTML = $t("Appconfig.content.content5");
        //         document.getElementById("highlight").classList.remove("activebutton");
        //         document.getElementById("tab").ariaPressed = false;

        //         let prueba = document.getElementsByTagName("button");
        //         for (let i = 0; i < prueba.length; i++) {
        //             prueba[i].classList.remove("highlightButtons");
        //         }
        //         let prueba2 = document.getElementsByTagName("a");
        //         for (let i = 0; i < prueba2.length; i++) {
        //             prueba2[i].classList.remove("highlightButtons");
        //         }
        //         let prueba3 = document.getElementsByTagName("th");
        //         for (let i = 0; i < prueba3.length; i++) {
        //             prueba3[i].classList.remove("highlightButtons");
        //         }
        //         let prueba4 = document.getElementsByClassName("p-radiobutton-box");
        //         for (let i = 0; i < prueba4.length; i++) {
        //             prueba4[i].classList.remove("highlightButtons");
        //         }
        //         let prueba5 = document.getElementsByClassName("slider");
        //         for (let i = 0; i < prueba5.length; i++) {
        //             prueba5[i].classList.remove("highlightButtons");
        //         }
        //         let prueba6 = document.getElementsByClassName("p-slider-handle");
        //         for (let i = 0; i < prueba6.length; i++) {
        //             prueba6[i].classList.remove("highlightButtons");
        //         }
        //         let prueba7 = document.getElementsByClassName("p-colorpicker-preview");
        //         for (let i = 0; i < prueba7.length; i++) {
        //             prueba7[i].classList.remove("highlightButtons");
        //         }
        //         let prueba8 = document.getElementsByClassName("p-inputswitch-slider");
        //         for (let i = 0; i < prueba8.length; i++) {
        //             prueba8[i].classList.remove("highlightButtons");
        //         }
        //     } else {
        //         this.highlight = true;
        //         // document.getElementById("highlight").innerHTML = $t("Appconfig.content.content4");
        //         document.getElementById("highlight").classList.add("activebutton");
        //         document.getElementById("tab").ariaPressed = true;

        //         let prueba = document.getElementsByTagName("button");
        //         for (let i = 0; i < prueba.length; i++) {
        //             prueba[i].classList.add("highlightButtons");
        //         }
        //         let prueba2 = document.getElementsByTagName("a");
        //         for (let i = 0; i < prueba2.length; i++) {
        //             prueba2[i].classList.add("highlightButtons");
        //         }
        //         let prueba3 = document.getElementsByTagName("th");
        //         for (let i = 0; i < prueba3.length; i++) {
        //             prueba3[i].classList.add("highlightButtons");
        //         }
        //         let prueba4 = document.getElementsByClassName("p-radiobutton-box");
        //         for (let i = 0; i < prueba4.length; i++) {
        //             prueba4[i].classList.add("highlightButtons");
        //         }
        //         let prueba5 = document.getElementsByClassName("p-button-info buttonAcc title active");
        //         for (let i = 0; i < prueba5.length; i++) {
        //             prueba5[i].classList.add("highlightButtons");
        //         }
        //         let prueba6 = document.getElementsByClassName("p-slider-handle");
        //         for (let i = 0; i < prueba6.length; i++) {
        //             prueba6[i].classList.add("highlightButtons");
        //         }
        //         let prueba7 = document.getElementsByClassName("p-colorpicker-preview");
        //         for (let i = 0; i < prueba7.length; i++) {
        //             prueba7[i].classList.add("highlightButtons");
        //         }
        //         let prueba8 = document.getElementsByClassName("p-inputswitch-slider");
        //         for (let i = 0; i < prueba8.length; i++) {
        //             prueba8[i].classList.add("highlightButtons");
        //         }
        //     }
        // },
        changeFocus() {
            this.checked = false;
            if (this.hightab == false) {
                this.hightab = true;
                document.getElementById("tab").classList.add("activebutton");
                document.styleSheets[0].insertRule("*:focus { background: orange !important; }", 1105);
                document.styleSheets[0].cssRules[0].style.background = "orange !important";
                document.getElementById("hamburguer").focus();
                this.hideConfigurator();
            } else {
                this.hightab = false;
                document.getElementById("tab").classList.remove("activebutton");
                document.getElementById("hamburguer").focus();
                document.styleSheets[0].deleteRule(1105);
            }
        }
    }
};
</script>

<style>
.activebutton {
    background: grey !important;
}

.activebuttonbug {
    background: pink !important;
}

.buttonAcc {
    width: 100%;
    margin-top: 0.3rem;
    padding: 0.3rem 0 0.3rem 0;
    border-radius: 0.4rem;
    border: solid 0.2rem var(--primary-color);
}

.reset {
    margin-top: 1.5rem;
    border-radius: 0.4rem;
    padding: 0.3rem 0.6rem 0.3rem 0.6rem;
    cursor: pointer;
}
.resetbugN {
    color: black;
}
.resetbugB {
    color: white;
}
.title {
    font-weight: bold;
    font-size: 1.1rem;
}
.line {
    border: solid 0.1rem var(--primary-color);
}

.fonts-title {
    font-size: 1.5em;
}

button {
    cursor: pointer;
}
/* .highlightButtons {
    border: 4px solid rgb(156, 1, 1) !important;
    border-radius: 0px !important;
} */
</style>
