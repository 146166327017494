<template>
    <!-- eslint-disable vue/no-v-model-argument -->
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <!-- LOADER -->
        <!-- <loading v-model:active="loader.isLoading" :can-cancel="false" :is-full-page="true" color="#007680" :width="loader.loaderSize" :height="loader.loaderSize" :opacity="loader.opacity" /> -->
        <loading v-model:active="loader.isLoading" :can-cancel="false" :is-full-page="true" color="#0C0C0C" :width="loader.loaderSize" :height="loader.loaderSize" :opacity="loader.opacity" />

        <!-- ERROR DIALOG -->
        <Dialog v-model:visible="showMessage" :breakpoints="{ '960px': '80vw' }" :style="{ width: '30vw' }" position="top">
            <div class="flex align-items-center flex-column pt-6 px-3">
                <i class="pi pi-times-circle" :style="{ fontSize: '5rem', color: 'var(--orange-900)' }" />
                <h5>Error</h5>
                <p :style="{ lineHeight: 1.5, textIndent: '1rem' }">
                    {{ errorMessage }}
                </p>
            </div>
            <template #footer>
                <div class="flex justify-content-center">
                    <Button label="OK" class="p-button-text" @click="toggleDialog" />
                </div>
            </template>
        </Dialog>

        <!-- REGISTER -->
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
            <div class="col-12 xl:col-6" style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, rgba(70, 150, 160, 0.7) 10%, rgba(70, 150, 160, 0) 30%)">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius: 53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0))">
                    <Button class="p-button-danger p-button-rounded p-button-text" icon="pi pi-times" style="top: -2rem !important" :aria-label="$t('accesibility.register.return')" @click="toggleLanding" />
                    <div class="grid flex flex-column align-items-center">
                        <div class="flex justify-content-center align-items-center border-circle" style="width: 3.2rem; height: 3.2rem">
                            <!-- <img src="/images/Logo_139x158.svg" alt="Logo de bemyvega" class="mb-5" style="width: 81px; height: 60px" aria-hidden="true" /> -->
                            <img src="/images/Logo_148x158N.svg" alt="Logo de bemyvega" class="mb-5" style="width: 81px; height: 60px" aria-hidden="true" />
                        </div>
                        <h1 class="text-900 font-bold text-4xl lg:text-5xl mb-2">
                            {{ $t("endpoints.register.header") }}
                        </h1>
                    </div>

                    <!-- REGISTER FORM -->
                    <form class="p-fluid" :aria-label="$t('accesibility.register.form')" @submit.prevent="handleSubmit(!v$.$invalid)">
                        <div class="field">
                            <!-- USERNAME -->
                            <div class="p-float-label p-input-icon-right mt-4" :aria-label="$t('accesibility.register.name')">
                                <i class="pi pi-user" aria-hidden="true" />
                                <InputText id="username" v-model="v$.username.$model" aria-labelledby="username" :class="{ 'p-invalid': v$.username.$invalid && submitted }" class="p-inputtext-lg" />
                                <label for="username" :class="{ 'p-error': v$.username.$invalid && submitted }">{{ $t("endpoints.register.body.username") }}</label>
                            </div>
                            <small v-if="(v$.username.required.$invalid && submitted) || v$.username.$pending.$response" class="p-error">{{ v$.username.required.$message.replace("Value", "Username") }}</small>
                            <small v-if="(v$.username.alphaNum.$invalid && submitted) || v$.username.$pending.$response" class="p-error">{{ v$.username.alphaNum.$message.replace("Value", "Username") }}</small>
                        </div>

                        <!-- EMAIL -->
                        <div class="field p-float-label p-input-icon-right mt-4" :aria-label="$t('accesibility.register.email')">
                            <i class="pi pi-envelope" role="none" aria-hidden="true" />
                            <InputText id="email" v-model="v$.email.$model" aria-labelledby="email" :class="{ 'p-invalid': v$.email.$invalid && submitted }" aria-describedby="email-error" class="p-inputtext-lg" />
                            <label for="email" :class="{ 'p-error': v$.email.$invalid && submitted }">{{ $t("endpoints.register.body.email") }}</label>

                            <span v-if="v$.email.$error && submitted">
                                <span v-for="(error, index) of v$.email.$errors" id="email-error" :key="index">
                                    <small class="p-error">{{ error.$message }}</small>
                                </span>
                            </span>
                            <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{ v$.email.required.$message.replace("Value", "Email") }}</small>
                        </div>

                        <!-- PASSWORD -->
                        <div class="field p-float-label p-input-icon-right mt-4" :aria-label="$t('accesibility.register.pass')">
                            <Password id="password" v-model="v$.password.$model" aria-labelledby="password" :class="{ 'p-invalid': v$.password.$invalid && submitted }" class="p-inputtext-lg" />
                            <label for="password" :class="{ 'p-error': v$.password.$invalid && submitted }">{{ $t("endpoints.register.body.password") }}</label>

                            <small v-if="(v$.password.required.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{ v$.password.required.$message }}</small>
                            <small v-else-if="(v$.password.passwordValidator.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{ v$.password.passwordValidator.$message }}</small>
                        </div>

                        <!-- REPEAT PASSWORD -->
                        <div class="field p-float-label p-input-icon-right mt-4" :aria-label="$t('accesibility.register.repeat-pass')">
                            <Password id="repeat-password" v-model="v$.repeatPassword.$model" aria-labelledby="repeat-password" :class="{ 'p-invalid': v$.repeatPassword.$invalid && submitted }" class="p-inputtext-lg" :feedback="false" />
                            <label for="repeat-password" :class="{ 'p-error': v$.repeatPassword.$invalid && submitted }">{{ $t("endpoints.register.body.repeat-password") }}</label>

                            <small v-if="(v$.repeatPassword.required.$invalid && submitted) || v$.repeatPassword.$pending.$response" class="p-error">{{ v$.repeatPassword.required.$message }}</small>
                            <small v-else-if="(v$.repeatPassword.sameAsPassword.$invalid && submitted) || v$.repeatPassword.$pending.$response" class="p-error">{{ v$.repeatPassword.sameAsPassword.$message }}</small>
                        </div>

                        <!-- MODE -->
                        <!-- <div class="formgrid grid"> -->
                        <!-- <div class="field col p-float-label p-input-icon-right mt-4" :aria-label="$t('accesibility.register.mode')">
                                <label for="center1" class="block text-900 font-medium text-xl mb-2" aria-hidden="true">{{ $t("endpoints.register.body.center.header") }}</label>
                                <Dropdown v-model="selectedMode" :options="modes" :placeholder="$t('endpoints.register.body.mode.placeholder')" />
                            </div> -->

                        <!-- CENTER -->
                        <!-- <div class="field col p-input-icon-right mt-4" :aria-label="$t('accesibility.register.center')">
                                <Dropdown id="centers" v-model="selectedCenter" :options="centers" option-label="name" :placeholder="$t('endpoints.register.body.center.placeholder')" aria-haspopup="true" aria-expanded="false" />
                            </div> -->
                        <!-- </div> -->

                        <!-- TOKEN -->
                        <div class="field mt-4 text-center" :aria-label="$t('accesibility.register.token')">
                            <!-- <legend class="inline text-900 font-medium text-xl 2 mt-2" role="none">Token</legend> -->
                            <div class="grid formgrid flex justify-content-center m-auto p-float-label p-input-icon-right">
                                <i class="pi pi-shield" aria-hidden="true" />
                                <InputText id="token" v-model="v$.token.$model" :class="{ 'p-invalid': v$.token.$invalid && submitted }" class="p-inputtext-lg" />
                                <label for="token" :class="{ 'p-error': v$.token.$invalid && submitted }">Token</label>
                            </div>
                            <small v-if="(v$.token.$invalid && submitted) || v$.token.$pending.$response" class="p-error">{{ v$.token.required.$message.replace("Value", "Token") }}</small>
                        </div>

                        <!-- TERMS AND CONDITIONS -->
                        <div class="field my-4">
                            <a href="" target="_blank">{{ $t("endpoints.register.body.terms-and-conditions") }}</a>
                            <div class="flex align-items-center my-2">
                                <Checkbox v-model="isConditionsAccepted" input-id="conditions" :binary="true" />
                                <label for="isConditionsAccepted" class="ml-2">{{ $t("endpoints.register.body.accept-terms-and-conditions") }}</label>
                            </div>
                            <small v-if="v$.isConditionsAccepted.$invalid && submitted" class="p-error">{{ v$.isConditionsAccepted.required.$message }}</small>
                        </div>

                        <!-- SUBMIT -->
                        <!-- <Button id="submit-register" :label="$t('endpoints.register.body.button')" class="w-full p-3 text-xl mt-1" style="background: #007680" type="submit" aria-describedby="Enviar datos" :aria-label="$t('accesibility.register.register')" /> -->
                        <Button id="submit-register" :label="$t('endpoints.register.body.button')" class="w-full p-3 text-xl mt-1" style="background: #0c0c0c" type="submit" aria-describedby="Enviar datos" :aria-label="$t('accesibility.register.register')" />

                        <!-- REDIRECT LOGIN -->
                        <div class="field mt-4 flex justify-content-center" aria-describedby="Ir a la página de login" :aria-label="$t('accesibility.register.login')">
                            <router-link to="/login" style="color: var(--primary-color)" :aria-label="$t('accesibility.register.gologin')">
                                {{ $t("endpoints.register.body.redirect") }}
                            </router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { email, required, alphaNum, helpers, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { passwordValidator, getId } from "../utils/utils";
import { RepositoryFactory } from "../repositories/RepositoryFactory";
import { mapState } from "vuex";

const CentersRepository = RepositoryFactory.get("centers");
const UsersRepository = RepositoryFactory.get("users");

export default {
    components: {
        Loading
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            username: "",
            email: "",
            password: "",
            repeatPassword: "",
            selectedMode: "student",
            token: "",
            isConditionsAccepted: false,
            submitted: false,
            showMessage: false,
            errorMessage: "",
            selectedCenter: null,
            // centers: [],
            modes: ["Teacher", "Student"]
        };
    },
    validations() {
        return {
            username: {
                required,
                alphaNum
            },
            email: {
                required,
                email
            },
            password: {
                required,
                passwordValidator: helpers.withMessage("Password must contain at least 6 characters with 1 uppercase or 1 number.", passwordValidator)
            },
            repeatPassword: {
                required,
                sameAsPassword: sameAs(this.password)
            },
            token: {
                required
            },
            isConditionsAccepted: {
                required,
                sameAs: sameAs(true)
            }
        };
    },
    computed: {
        ...mapState(["loader"]),
        logoColor() {
            if (this.$appState.darkTheme) return "white";
            return "dark";
        }
    },
    created() {
        document.title = this.$t("accesibility.title.register");
        this.getCenters();
    },
    methods: {
        async getCenters() {
            let response = await CentersRepository.getCenters();
            const centers = response.data.results;

            for (let i = 0; i < centers.length; i++) {
                if (centers[i].name == "Bemyvega") {
                    this.selectedCenter = centers[i];
                }
            }
        },
        async register() {
            const formData = {
                username: this.username,
                email: this.email,
                password: this.password,
                center_id: getId(this.selectedCenter.self),
                mode: this.selectedMode.toLowerCase(),
                token: this.token
            };

            console.log(this.selectedMode.toLowerCase());

            try {
                await UsersRepository.createUser(formData);
                this.$router.push("/login");
            } catch (error) {
                this.success = false;
                this.errorMessage = error.response.data.detail;
                this.toggleDialog();
            }
        },
        handleSubmit(isFormValid) {
            this.submitted = true;

            // if (!isFormValid || this.selectedCenter == null || this.selectedMode == null) {
            if (!isFormValid || this.selectedMode == null) {
                console.log(this.isConditionsAccepted);
                return;
            }

            this.register();
        },
        toggleLogin() {
            this.$router.push("/login");
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;

            if (!this.showMessage) {
                this.resetForm();
            }
        },
        toggleLanding() {
            this.$router.push("/");
        },
        resetForm() {
            this.username = "";
            this.email = "";
            this.password = "";
            // this.selectedCenter = null;
            this.submitted = false;
        }
    }
};
</script>

<style scoped>
.pi-eye {
    transform: scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform: scale(1.6);
    margin-right: 1rem;
}
</style>
