import api from "./Repository";

const END_POINT = "/centers";

export default {
    // GET
    getCenters() {
        return api.get(`${END_POINT}/`);
    },
    getCenter(centerId) {
        return api.get(`${END_POINT}/${centerId}`);
    },
    getUsers(centerId) {
        return api.get(`${END_POINT}/${centerId}/users/`);
    },
    getCategories(centerId) {
        return api.get(`${END_POINT}/${centerId}/categories/`);
    },
    getVegas(centerId) {
        return api.get(`${END_POINT}/${centerId}/vegas/`);
    },
    getRooms(centerId) {
        return api.get(`${END_POINT}/${centerId}/rooms/`);
    },

    // POST
    createCenter(payload) {
        return api.post(`${END_POINT}/`, payload);
    },

    // DELETE
    deleteCenter(centerId) {
        return api.delete(`${END_POINT}/${centerId}`);
    }
};
