import api from "./Repository";

const END_POINT = "/rooms";

export default {
    // GET
    getRooms() {
        return api.get(`${END_POINT}/`);
    },
    getRoom(roomId) {
        return api.get(`${END_POINT}/${roomId}`);
    },
    // enterStreaming(roomId, userId, active = true) {
    //     return api.get(`${END_POINT}/${roomId}/users/${userId}?active=${active}`);
    // },
    getUsersWatchingStream(roomId, userId) {
        return api.get(`${END_POINT}/${roomId}/users/${userId}`);
    },

    // POST
    createRoom(payload) {
        return api.post(`${END_POINT}/`, payload);
    },

    // DELETE
    deleteRoom(roomId) {
        return api.delete(`${END_POINT}/${roomId}`);
    }
};
