<template id="sidebar" role="none">
    <nav class="layout-menu-container" role="navigation" aria-label="Menu" aria-labelledby="nav2">
        <AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown" />
    </nav>
</template>

<script>
import AppSubmenu from "./AppSubmenu";

export default {
    components: {
        AppSubmenu: AppSubmenu
    },
    props: {
        model: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    emits: ["menuitem-click"],
    computed: {
        darkTheme() {
            return this.$appState.darkTheme;
        }
    },
    methods: {
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event);
        },
        onKeyDown(event) {
            const nodeElement = event.target;
            if (event.code === "Enter" || event.code === "Space") {
                nodeElement.click();
                event.preventDefault();
            }
        },
        bannerImage() {
            return this.$appState.darkTheme ? "images/banner-primeblocks-dark.png" : "images/banner-primeblocks.png";
        }
    }
};
</script>
