import api from "./Repository";

const END_POINT = "/recordings";

export default {
    // GET
    getStreams() {
        return api.get(`${END_POINT}/streams/`);
    },
    getStreamsStatistics(stream) {
        return api.get(`${END_POINT}/streams/${stream}/statistics/`);
    },
    getDirectories(recording_id) {
        return api.get(`${END_POINT}/${recording_id}/`);
    },
    getRecordings(recording_id, directory_name) {
        return api.get(`${END_POINT}/${recording_id}/${directory_name}/`);
    },
    downloadRecordings(recording_id, directory_name) {
        let headers = { responseType: "blob" };
        return api.get(`${END_POINT}/${recording_id}/${directory_name}/download/`, { headers: headers });
    },

    // POST
    startRecord(recording_id) {
        return api.post(`${END_POINT}/${recording_id}/start-record/`);
    },
    stopRecord(recording_id, payload) {
        return api.post(`${END_POINT}/${recording_id}/stop-record/`, payload);
    },
    showRecord(recording_id, payload) {
        return api.post(`${END_POINT}/${recording_id}/records/`, payload);
    },

    startHLSDump(recording_id) {
        return api.post(`${END_POINT}/${recording_id}/start-hls-dump/`);
    },
    stopHLSDump(recording_id) {
        return api.post(`${END_POINT}/${recording_id}/stop-hls-dump/`);
    },

    // PUT
    editNameRecordings(recording_id, directory_name, new_name) {
        return api.put(`${END_POINT}/${recording_id}/${directory_name}/${new_name}`);
    },

    // DELETE
    deleteRecordings(recording_id, directory_name) {
        return api.delete(`${END_POINT}/${recording_id}/${directory_name}`);
    }
};
