export default {
  "app": {
    "save": {
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
      "unsaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
    },
    "menu": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENU"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My subjects"])},
      "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My rooms"])},
      "sesions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My sessions"])},
      "recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordings"])}
    },
    "topBar": {
      "myprofile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
    },
    "recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available recordings"])},
    "recordings_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change the name of the recording?"])},
    "not_recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There aren't recordings in the session"])}
  },
  "endpoints": {
    "home": {
      "table": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available equipment"])},
        "columns": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
          "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id number"])}
        }
      },
      "cards": {
        "1": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available equipment"])}
        },
        "2": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My subjects"])}
        }
      }
    },
    "categories": {
      "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the category?"])},
      "tables": {
        "table1": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My subjects"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New subject"])},
          "dialog": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category details"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
            "buttons": {
              "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
            }
          },
          "columns": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])}
          }
        },
        "table2": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center subjects"])},
          "sort": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by level"])},
            "options": {
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Higher course"])},
              "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower course"])}
            }
          },
          "cards": {
            "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])}
          }
        }
      }
    },
    "rooms": {
      "card": {
        "header_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free sessions"])},
        "header_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free sessions"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New session"])},
        "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the session?"])},
        "dialog": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session details"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "subject": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects"])}
          },
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
          "buttons": {
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
          }
        }
      },
      "table": {
        "header_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My sessions"])},
        "header_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recorded sessions available"])},
        "columns": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])}
        }
      }
    },
    "profile": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
      "body": {
        "user_mode": {
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
          "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])}
        },
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center"])},
        "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])}
      }
    },
    "register": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
      "body": {
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
        "mode": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a mode"])}
        },
        "center": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a center"])}
        },
        "type": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose one"])}
        },
        "accept-terms-and-conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the terms and conditions"])},
        "terms-and-conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
        "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Already have an account?"])}
      }
    },
    "login": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "body": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
        "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not registered?"])},
        "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])}
      }
    },
    "forgot-password": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover password"])},
      "body": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send email"])}
      },
      "dialog": {
        "success": {
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email with your password has been sent to"])}
        },
        "error": {
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error..."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is not registered"])}
        }
      }
    },
    "access": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Denied"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necesary permisions. Please contact admins."])},
      "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to login"])}
    },
    "error": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Occured"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested resource is not available."])},
      "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Dashboard"])}
    },
    "notfound": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks like you are lost"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested resource is not available."])},
      "body": {
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions"])},
        "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisssion Manager"])}
      },
      "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Dashboard"])}
    },
    "pre-stream": {
      "header-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to join?"])},
      "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You camera and audio won't be shared until you specify it in the streaming."])},
      "join-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join now"])}
    },
    "code-access": {
      "header-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join the BemyVega experience"])},
      "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just enter the code here"])},
      "error-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no room with the entered code"])}
    },
    "landing": {
      "topbar": {
        "beginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginning"])},
        "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
      },
      "body": {
        "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For unlimited"])},
        "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learning"])},
        "message1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology is the tool that enables us to access training without limits, removing the barriers that do not allow us to advance."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
        "message2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover all our features"])},
        "cards": {
          "1": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy installation and use"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn on, connect the device to the Wi-Fi network and... that's it!"])}
          },
          "2": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibility tools"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom, filters, change of scales, contrasts..."])}
          },
          "3": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-time subtitles"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We transcribe in real time the information that the speaker transfers to the users"])}
          },
          "4": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaker follow-up"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic speaker tracking using AI algorithms"])}
          },
          "5": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simultaneous streaming and session recording"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broadcast in real time both remotely and in person"])}
          },
          "6": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compatible with various collaboration platforms"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Teams, Zoom, Google Meet"])}
          },
          "7": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various views available"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick and intuitive selection of any of the three views"])}
          },
          "8": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own local network"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows users who are in the room to use the equipment without the need for an internet connection"])}
          },
          "9": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own mobile applications"])},
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available on Android and iOS"])}
          }
        }
      },
      "footer": {
        "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
        "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social media"])},
        "subheaders": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For whom"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
        }
      }
    }
  },
  "Appconfig": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Themes"])},
    "themes": {
      "theme1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])},
      "theme2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft"])},
      "theme3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark"])}
    },
    "header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "filters": {
      "filter1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monochrome"])},
      "filter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hight contrast"])},
      "filter3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black on white"])},
      "filter4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White on black"])}
    },
    "header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font family"])},
    "content": {
      "content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original"])},
      "content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Readable"])},
      "content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dyslexia"])},
      "content4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlight links"])},
      "content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No highlight links"])}
    },
    "header5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADHD"])},
    "tdah": {
      "tdah1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read focus"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
      "tdah2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reading guide"])}
    },
    "header6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visually impaired"])},
    "visual": {
      "highlightlinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlight links"])},
      "highlighttab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlight focus"])}
    },
    "brightness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brightness"])},
    "saturate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturate"])},
    "contrast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrast"])}
  },
  "Media": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playing"])},
    "button1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLACKBOARD"])},
    "button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEACHER"])},
    "button3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRESENTATION"])}
  },
  "accesibility": {
    "buttonStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to top of page"])},
    "menu1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open menu"])},
    "list1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation on the current page"])},
    "list2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login and user registration"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction"])},
    "webBemyvega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Bemyvega's website"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
    "landing": {
      "RRSS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social media"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to bemyvega's facebook "])},
      "youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to bemyvega's youtube "])},
      "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to bemyvega's linkedin"])},
      "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to bemyvega's instagram"])},
      "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to bemyvega's twitter"])}
    },
    "login": {
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to landing page"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
      "goregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to register"])},
      "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password recovery"])}
    },
    "register": {
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to landing page"])},
      "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "repeat-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
      "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center"])},
      "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "gologin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to login"])},
      "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])}
    },
    "forgot-password": {
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to landing page"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "send-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send email"])}
    },
    "error": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error code"])}
    },
    "dashboard": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information from"])},
      "vegas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available equipment"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center categories"])},
      "mycategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My subjects"])}
    },
    "rooms": {
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete room"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copy room link to clipboard "])},
      "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to streaming room"])}
    },
    "categories": {
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator of the category"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories available at the center"])},
      "gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage my categories"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete category"])}
    },
    "media": {
      "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player"])},
      "player1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blackboard display"])},
      "player2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher display"])},
      "player3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentation display"])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streaming chat"])},
      "activeUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last users online"])},
      "streaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streaming media player"])},
      "deferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deferred media player"])},
      "recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available recordings"])}
    },
    "recordings": {
      "button1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play sessionn"])},
      "button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download session"])},
      "button3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update recording name"])},
      "button4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete recording"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available options"])}
    },
    "controls": {
      "controls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media player controls"])},
      "buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display controls"])},
      "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
      "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
      "mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute on"])},
      "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute off"])},
      "sonido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio slider"])},
      "maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximize screen"])},
      "minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimize screen"])},
      "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start record"])},
      "norecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop record"])},
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to left display"])},
      "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to right display"])},
      "timeIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current time"])},
      "timeOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time slider"])}
    },
    "topbar": {
      "toolbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toolbar"])},
      "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega logo"])},
      "sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open/close sidebar"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User settings"])}
    },
    "appconfig": {
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open accesibility panel"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close accesibility panel"])},
      "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decrease font"])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase font"])},
      "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filters"])}
    },
    "title": {
      "landing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega: Start"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access denied"])},
      "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource not found"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega: Start"])},
      "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Bemyvega: Rooms"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega: Categories"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi profile"])},
      "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega: Streaming media player"])},
      "deferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega: Deferred media player"])},
      "recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemyvega: Recordings"])}
    }
  }
}