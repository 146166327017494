<template>
    <div class="surface-0 overflow-hidden">
        <Button v-if="hasScrolled" class="p-button-rounded" icon="pi pi-arrow-up" style="position: fixed; right: 5px; bottom: 5px; z-index: 1" :aria-label="$t('accesibility.buttonStart')" @click="smoothScroll('header')"> </Button>

        <header class="py-4 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 flex align-items-center justify-content-between relative lg:static">
            <!-- <router-link v-if="this.$route.path == '/landing'" to="/landing" class="flex align-items-center">
                <img src="/images/Logo_85x63.svg" alt="Bemyvega Logo" height="60" class="mr-0 lg:mr-2" />
                <span class="text-900 font-medium text-2xl line-height-3 mr-8">Bemyvega</span>
            </router-link>
           <router-link v-else to="/home" class="flex align-items-center"> <img src="/images/Logo_85x63.svg" alt="Bemyvega Logo" height="60" class="mr-0 lg:mr-2" />
                <span class="text-900 font-medium text-2xl line-height-3 mr-8">Bemyvega</span> 
            </router-link> -->

            <!-- <img src="https://bemyvega.com/wp-content/uploads/2022/01/bemyvega-logotipo.png" alt="Bemyvega Logo" height="60" class="mr-2 mb-5" /> -->
            <img src="https://www.bemyvega.com/wp-content/uploads/2023/10/Black-_-Logotipo-horizontal-Bemyvega-1024x206.png" alt="Bemyvega Logo" height="60" class="mr-2 mb-5" />
            <!-- <span class="text-900 font-medium text-2xl line-height-3 mr-8">Bemyvega</span> -->

            <!-- Este elemento es el menu de movil -->
            <a v-ripple v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }" class="cursor-pointer block lg:hidden text-700 p-ripple" role="navigation" tabindex="0" aria-haspopup="true" :aria-label="$t('accesibility.menu1')">
                <i class="pi pi-bars text-4xl" role="none"></i>
            </a>
            <div class="surface-0 align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2 lg:ml-8" style="top: 92%" role="navigation">
                <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer" :aria-label="$t('accesibility.list1')">
                    <li class="mr-2">
                        <Button v-ripple class="text-900 font-medium line-height-3 p-ripple p-button-text p-button-rounded border-none" @click="smoothScroll('#hero')">
                            {{ $t("endpoints.landing.topbar.beginning") }}
                        </Button>
                    </li>
                    <li class="mr-3">
                        <Button v-ripple class="text-900 font-medium line-height-3 p-ripple p-button-text p-button-rounded border-none" @click="smoothScroll('#features')">
                            {{ $t("endpoints.landing.topbar.features") }}
                        </Button>
                    </li>
                    <li>
                        <Button id="button_language" class="p-button-text p-button-rounded border-none font-medium line-height-3 text-blue-900" label="Toggle" aria-haspopup="true" @click="toggleLang"> {{ $t("endpoints.landing.topbar.language") }} <i class="pi pi-arrow-down text-xs ml-1" aria-hidden="true"></i> </Button>
                        <Menu id="overlay_menu" ref="lang" tabindex="0" :model="langItems" :popup="true" />
                    </li>
                </ul>
                <div class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0" :aria-label="$t('accesibility.list2')">
                    <!-- Link to login -->
                    <!-- <Button id="login" :label="$t('endpoints.landing.topbar.login')" class="p-button-text p-button-rounded border-none font-light text-white line-height-2" style="background: #007680" role="link" @click="toggleLogin"></Button>
                    <Button id="register" :label="$t('endpoints.landing.topbar.register')" class="p-button-rounded border-none ml-5 font-light text-white line-height-2" style="background: #007680" role="link" @click="toggleRegister"></Button> -->
                    <Button id="login" :label="$t('endpoints.landing.topbar.login')" class="p-button-text p-button-rounded border-none font-light text-white line-height-2" style="background: #0c0c0c" role="link" @click="toggleLogin"></Button>
                    <Button id="register" :label="$t('endpoints.landing.topbar.register')" class="p-button-rounded border-none ml-5 font-light text-white line-height-2" style="background: #0c0c0c" role="link" @click="toggleRegister"></Button>
                </div>
            </div>
        </header>

        <div id="hero" class="grid py-6 px-4 lg:px-8 relative" style="height: auto" :aria-label="$t('accesibility.introduction')">
            <div class="mx-4 md:mx-8 mt-0 md:mt-4">
                <h1 class="text-6xl font-bold text-gray-900 line-height-2 font-light block">{{ $t("endpoints.landing.body.header1") }} {{ $t("endpoints.landing.body.header2") }}</h1>
                <p class="font-bold text-2xl line-height-3 md:mt-3 text-gray-900">{{ $t("endpoints.landing.body.message1") }}</p>
                <!-- <Button :label="$t('endpoints.landing.body.button')" class="p-button-rounded text-xl border-none mt-5 font-normal text-white line-height-3 px-8" style="background: #007680; margin-bottom: 4rem" onclick="window.location.href='https://bemyvega.com'" :aria-label="$t('accesibility.webBemyvega')" role="link"></Button> -->
                <Button :label="$t('endpoints.landing.body.button')" class="p-button-rounded text-xl border-none mt-5 font-normal text-white line-height-3 px-8" style="background: #0c0c0c; margin-bottom: 4rem" onclick="window.location.href='https://bemyvega.com'" :aria-label="$t('accesibility.webBemyvega')" role="link"></Button>
            </div>
            <img src="images/startup.png" class="bottom-0" alt="Estamos trabajando en ello" style="right: 20%" />
        </div>

        <div id="features" class="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8 container" :aria-label="$t('accesibility.features')" role="main">
            <div class="grid justify-content-center">
                <div class="col-12 text-center mt-8 mb-4">
                    <h2 class="text-900 font-normal mb-2">{{ $t("endpoints.landing.body.message2") }}</h2>
                    <span class="text-600 text-2xl"></span>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height: auto; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))">
                        <div class="p-3 surface-card h-full" style="border-radius: 8px">
                            <div class="flex align-items-center justify-content-center bg-yellow-200 mb-3" style="width: 3.5rem; height: 3.5rem; border-radius: 10px">
                                <i class="pi pi-fw pi-video text-2xl text-yellow-700" aria-hidden="true"></i>
                            </div>
                            <h3 class="mb-2 text-900">{{ $t("endpoints.landing.body.cards.1.header") }}</h3>
                            <span class="text-600">{{ $t("endpoints.landing.body.cards.1.msg") }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height: auto; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(145, 226, 237, 0.2), rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(172, 180, 223, 0.2))">
                        <div class="p-3 surface-card h-full" style="border-radius: 8px">
                            <div class="flex align-items-center justify-content-center bg-cyan-200 mb-3" style="width: 3.5rem; height: 3.5rem; border-radius: 10px">
                                <i class="pi pi-fw pi-palette text-2xl text-cyan-700" aria-hidden="true"></i>
                            </div>
                            <h3 class="mb-2 text-900">{{ $t("endpoints.landing.body.cards.2.header") }}</h3>
                            <span class="text-600">{{ $t("endpoints.landing.body.cards.2.msg") }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height: auto; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(145, 226, 237, 0.2), rgba(172, 180, 223, 0.2)), linear-gradient(180deg, rgba(172, 180, 223, 0.2), rgba(246, 158, 188, 0.2))">
                        <div class="p-3 surface-card h-full" style="border-radius: 8px">
                            <div class="flex align-items-center justify-content-center bg-purple-200 mb-3" style="width: 3.5rem; height: 3.5rem; border-radius: 10px">
                                <i class="pi pi-fw pi-eye text-2xl text-purple-700" aria-hidden="true"></i>
                            </div>
                            <h3 class="mb-2 text-900">{{ $t("endpoints.landing.body.cards.3.header") }}</h3>
                            <span class="text-600">{{ $t("endpoints.landing.body.cards.3.msg") }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height: auto; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(187, 199, 205, 0.2), rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(145, 210, 204, 0.2))">
                        <div class="p-3 surface-card h-full" style="border-radius: 8px">
                            <div class="flex align-items-center justify-content-center bg-bluegray-200 mb-3" style="width: 3.5rem; height: 3.5rem; border-radius: 10px">
                                <i class="pi pi-fw pi-id-card text-2xl text-bluegray-700" aria-hidden="true"></i>
                            </div>
                            <h3 class="mb-2 text-900">{{ $t("endpoints.landing.body.cards.4.header") }}</h3>
                            <span class="text-600">{{ $t("endpoints.landing.body.cards.4.msg") }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height: auto; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(187, 199, 205, 0.2), rgba(246, 158, 188, 0.2)), linear-gradient(180deg, rgba(145, 226, 237, 0.2), rgba(160, 210, 250, 0.2))">
                        <div class="p-3 surface-card h-full" style="border-radius: 8px">
                            <div class="flex align-items-center justify-content-center bg-orange-200 mb-3" style="width: 3.5rem; height: 3.5rem; border-radius: 10px">
                                <i class="pi pi-fw pi-users text-2xl text-orange-700" aria-hidden="true"></i>
                            </div>
                            <h3 class="mb-2 text-900">{{ $t("endpoints.landing.body.cards.5.header") }}</h3>
                            <span class="text-600">{{ $t("endpoints.landing.body.cards.5.msg") }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height: auto; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(251, 199, 145, 0.2), rgba(246, 158, 188, 0.2)), linear-gradient(180deg, rgba(172, 180, 223, 0.2), rgba(212, 162, 221, 0.2))">
                        <div class="p-3 surface-card h-full" style="border-radius: 8px">
                            <div class="flex align-items-center justify-content-center bg-blue-200 mb-3" style="width: 3.5rem; height: 3.5rem; border-radius: 10px">
                                <i class="pi pi-fw pi-globe text-2xl text-blue-700" aria-hidden="true"></i>
                            </div>
                            <h3 class="mb-2 text-900">{{ $t("endpoints.landing.body.cards.6.header") }}</h3>
                            <span class="text-600">{{ $t("endpoints.landing.body.cards.6.msg") }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 mt-4 lg:mt-0">
                    <div style="height: auto; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(145, 210, 204, 0.2), rgba(160, 210, 250, 0.2)), linear-gradient(180deg, rgba(187, 199, 205, 0.2), rgba(145, 210, 204, 0.2))">
                        <div class="p-3 surface-card h-full" style="border-radius: 8px">
                            <div class="flex align-items-center justify-content-center bg-teal-200 mb-3" style="width: 3.5rem; height: 3.5rem; border-radius: 10px">
                                <i class="pi pi-fw pi-th-large text-2xl text-teal-700" aria-hidden="true"></i>
                            </div>
                            <h3 class="mb-2 text-900">{{ $t("endpoints.landing.body.cards.7.header") }}</h3>
                            <span class="text-600">{{ $t("endpoints.landing.body.cards.7.msg") }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 mt-4 lg:mt-0">
                    <div style="height: auto; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(145, 210, 204, 0.2), rgba(212, 162, 221, 0.2)), linear-gradient(180deg, rgba(251, 199, 145, 0.2), rgba(160, 210, 250, 0.2))">
                        <div class="p-3 surface-card h-full" style="border-radius: 8px">
                            <div class="flex align-items-center justify-content-center bg-blue-200 mb-3" style="width: 3.5rem; height: 3.5rem; border-radius: 10px">
                                <i class="pi pi-fw pi-wifi text-2xl text-blue-700" aria-hidden="true"></i>
                            </div>
                            <h3 class="mb-2 text-900">{{ $t("endpoints.landing.body.cards.8.header") }}</h3>
                            <span class="text-600">{{ $t("endpoints.landing.body.cards.8.msg") }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg-4 mt-4 lg:mt-0">
                    <div style="height: auto; padding: 2px; border-radius: 10px; background: linear-gradient(90deg, rgba(160, 210, 250, 0.2), rgba(212, 162, 221, 0.2)), linear-gradient(180deg, rgba(246, 158, 188, 0.2), rgba(212, 162, 221, 0.2))">
                        <div class="p-3 surface-card h-full" style="border-radius: 8px">
                            <div class="flex align-items-center justify-content-center bg-indigo-200" style="width: 3.5rem; height: 3.5rem; border-radius: 10px">
                                <i class="pi pi-fw pi-mobile text-2xl text-indigo-700" aria-hidden="true"></i>
                            </div>
                            <h3 class="mb-2 text-900">{{ $t("endpoints.landing.body.cards.9.header") }}</h3>
                            <span class="text-600">{{ $t("endpoints.landing.body.cards.9.msg") }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer id="contact" class="py-4 px-4 mx-0 mt-8 lg:mx-8">
            <div class="grid justify-content-between">
                <!-- <img src="https://bemyvega.com/wp-content/uploads/2020/08/bemyvega-logotipo.png" alt="Logo bemyvega" height="60" class="mr-2 mb-5" /> -->
                <img src="https://www.bemyvega.com/wp-content/uploads/2023/10/Black-_-Logotipo-horizontal-Bemyvega-1024x206.png" alt="Logo bemyvega" height="60" class="mr-2 mb-5" />

                <!-- <span class="text-900 font-medium text-2xl line-height-3 mr-8">Bemyvega</span> -->

                <div class="col-12 md:col-7">
                    <div class="grid text-center md:text-left">
                        <!-- <div class="col-12 md:col-4">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">{{ $t("endpoints.landing.footer.header1") }}</h4>
                            <a href="https://bemyvega.com/nosotros/" class="line-height-3 text-xl block cursor-pointer mb-2 text-700" target="_blank">{{ $t("endpoints.landing.footer.subheaders.1") }}</a>
                            <a href="https://bemyvega.com/para-quien/" class="line-height-3 text-xl block cursor-pointer mb-2 text-700" target="_blank">{{ $t("endpoints.landing.footer.subheaders.2") }}</a>
                            <a href="https://bemyvega.com/productos/" class="line-height-3 text-xl block cursor-pointer mb-2 text-700" target="_blank">{{ $t("endpoints.landing.footer.subheaders.3") }}</a>
                            <a href="https://bemyvega.com/contacto/" class="line-height-3 text-xl block cursor-pointer mb-2 text-700" target="_blank">{{ $t("endpoints.landing.footer.subheaders.4") }}</a>
                        </div> -->

                        <!-- <div class="col-12 md:col-4 mt-4 md:mt-0">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">{{ $t("endpoints.landing.footer.header2") }}</h4>
                            <a href="https://www.facebook.com/bemyvegacom/" target="_blank" aria-label="Facebook"><i class="pi pi-fw pi-facebook text-blue-700" style="font-size: 3rem"></i></a>
                            <a href="https://youtube.com/channel/UC1RYuWmfDBTEIXLn4oBhrpg" target="_blank" aria-label="Youtube"><i class="pi pi-fw pi-youtube text-pink-900" style="font-size: 3rem"></i></a>
                            <a href="https://www.linkedin.com/company/bemyvega" target="_blank" aria-label="Linkedin"><i class="pi pi-fw pi-linkedin text-blue-700" style="font-size: 3rem"></i></a>
                            <a href="https://instagram.com/bemyvega?utm_medium=copy_link" target="_blank" aria-label="Instagram"><i class="pi pi-fw pi-instagram text-pink-700" style="font-size: 3rem"></i></a>
                            <a href="https://twitter.com/bemyvega?lang=es" target="_blank" aria-label="Twitter"><i class="pi pi-fw pi-twitter text-blue-500" style="font-size: 3rem"></i></a>
                        </div> -->

                        <div class="col-12 md:col-4">
                            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column cursor-pointer text-center">
                                <li class="m-auto">
                                    <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">{{ $t("endpoints.landing.footer.header1") }}</h4>
                                </li>
                                <li class="m-auto">
                                    <a href="https://bemyvega.com/nosotros/" class="line-height-3 text-xl block cursor-pointer mb-2 text-700" target="_blank">{{ $t("endpoints.landing.footer.subheaders.1") }}</a>
                                </li>
                                <li class="m-auto">
                                    <a href="https://bemyvega.com/para-quien/" class="line-height-3 text-xl block cursor-pointer mb-2 text-700" target="_blank">{{ $t("endpoints.landing.footer.subheaders.2") }}</a>
                                </li>
                                <li>
                                    <a href="https://bemyvega.com/productos/" class="line-height-3 text-xl block cursor-pointer mb-2 text-700" target="_blank">{{ $t("endpoints.landing.footer.subheaders.3") }}</a>
                                </li>
                                <li class="m-auto">
                                    <a href="https://bemyvega.com/contacto/" class="line-height-3 text-xl block cursor-pointer mb-2 text-700" target="_blank">{{ $t("endpoints.landing.footer.subheaders.4") }}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="col-12 md:col-4 mt-4 md:mt-0">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">{{ $t("endpoints.landing.footer.header2") }}</h4>
                            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none lg:flex-row cursor-pointer text-center" :aria-label="$t('accesibility.landing.RRSS')">
                                <li class="m-auto">
                                    <a href="https://www.facebook.com/bemyvegacom/" target="_blank" :aria-label="$t('accesibility.landing.facebook')"><i class="pi pi-fw pi-facebook text-blue-700" style="font-size: 3rem"></i></a>
                                </li>
                                <li class="m-auto">
                                    <a href="https://youtube.com/channel/UC1RYuWmfDBTEIXLn4oBhrpg" target="_blank" :aria-label="$t('accesibility.landing.youtube')"><i class="pi pi-fw pi-youtube text-pink-900" style="font-size: 3rem"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/bemyvega" target="_blank" :aria-label="$t('accesibility.landing.linkedin')"><i class="pi pi-fw pi-linkedin text-blue-700" style="font-size: 3rem"></i></a>
                                </li>
                                <li class="m-auto">
                                    <a href="https://instagram.com/bemyvega?utm_medium=copy_link" target="_blank" :aria-label="$t('accesibility.landing.instagram')"><i class="pi pi-fw pi-instagram text-pink-700" style="font-size: 3rem"></i></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/bemyvega?lang=es" target="_blank" :aria-label="$t('accesibility.landing.twitter')"><i class="pi pi-fw pi-twitter text-blue-500" style="font-size: 3rem"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import { isLoggedIn, getAuthToken, getUserInfo } from "../utils/auth";
import { env_config } from "../../config";

export default {
    data() {
        return {
            langItems: [
                {
                    label: "Español",
                    command: () => {
                        this.$root.$i18n.locale = "es";
                        localStorage.setItem("lang", "es");
                    }
                },
                {
                    label: "English",
                    command: () => {
                        this.$root.$i18n.locale = "en";
                        localStorage.setItem("lang", "en");
                    }
                }
            ],
            hasScrolled: false
        };
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return "white";
            return "dark";
        }
    },
    created() {
        if (isLoggedIn()) {
            const URL = `${env_config.API_BASE_DOMAIN}/api/v1/login/test-token`;
            fetch(URL, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${getAuthToken()}`
                }
            }).then((response) => {
                if (response.ok) {
                    let { user_mode } = getUserInfo();
                    if (user_mode == "teacher") {
                        this.$router.push("/rooms");
                    } else {
                        this.$router.push("/code-access");
                    }
                }
            });
        }

        document.title = this.$t("accesibility.title.landing");
        window.addEventListener("scroll", this.handleScroll);
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    methods: {
        smoothScroll(id) {
            document.querySelector(id).scrollIntoView({
                behavior: "smooth"
            });
        },
        toggleLang(event) {
            this.$refs.lang.toggle(event);

            // document.getElementById("button_language").addEventListener(
            //     "keydown",
            //     function (e) {
            //         if (e.keyCode === 40) {
            //             console.log(document.getElementById("overlay_menu").children);
            //             console.log("pulsando abajo con menu activo");
            //         }
            //         if (e.keyCode === 38) {
            //             console.log("pulsando arriba con menu activo");
            //         }
            //     }.bind(this)
            // );
        },
        toggleLogin() {
            this.$router.push("/login");
        },
        toggleRegister() {
            this.$router.push("/register");
        },
        handleScroll() {
            if (window.scrollY > window.innerHeight / 2) {
                this.hasScrolled = true;
            }
            if (window.scrollY < window.innerHeight / 2) {
                this.hasScrolled = false;
            }
        }
    }
};
</script>

<style>
#hero {
    /* background: linear-gradient(0deg, rgba(70, 150, 160, 0.2), rgba(70, 150, 160, 0.2)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #007680 0%, #afefdb 100%); */
    background: linear-gradient(0deg, rgba(30, 83, 129, 0.2), rgba(30, 83, 129, 0.2)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #1936dd 0%, #ffffff 100%);
    height: 700px;
    overflow: hidden;
}

@media screen and (min-width: 768px) {
    #hero {
        -webkit-clip-path: ellipse(150% 87% at 93% 13%);
        clip-path: ellipse(150% 87% at 93% 13%);
        height: 530px;
    }
}

@media screen and (min-width: 1300px) {
    #hero > img {
        position: absolute;
    }

    #hero > div > p {
        max-width: 450px;
    }
}

@media screen and (max-width: 1300px) {
    #hero {
        height: 600px;
    }

    #hero > img {
        position: static;
        transform: scale(1);
        margin-left: auto;
    }

    #hero > div {
        width: 100%;
    }

    #hero > div > p {
        width: 100%;
        max-width: 100%;
    }
}
</style>
