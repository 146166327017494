<template>
    <nav class="layout-topbar" :aria-label="$t('accesibility.topbar.toolbar')" aria-labelledby="nav1" :style="{ position: ['/demo/streaming', '/demo/vod', '/demo/bidirectional'].includes($route.path) ? 'sticky' : 'fixed' }">
        <button id="hamburguer" aria-haspopup="true" :aria-label="$t('accesibility.topbar.sidebar')" class="p-link md:layout-menu-button layout-topbar-button md:flex-order-1 md:mx-5" @click="onMenuToggle">
            <i role="none" class="pi pi-bars" aria-hidden="true"></i>
        </button>
        <div role="banner" :aria-label="$t('accesibility.topbar.logo')" class="md:flex-order-0">
            <a v-if="userMode == 'teacher'" href="/rooms">
                <img :src="logo" alt="Bemyvega Logo" height="40" />
            </a>
            <a v-else href="/code-access">
                <img :src="logo" alt="Bemyvega Logo" height="60" />
            </a>
        </div>
        <button v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }" class="p-link layout-topbar-menu-button layout-topbar-button" aria-controls="langUser" aria-haspopup="true" :aria-label="$t('accesibility.topbar.settings')">
            <i role="none" aria-hidden="true" class="pi pi-ellipsis-v"></i>
        </button>
        <div id="langUser" class="layout-topbar-menu hidden lg:flex origin-top md:flex-order-2" role="menu">
            <Menu ref="lang" :model="overlayLangItems" :popup="true" />
            <Button id="language-button" class="p-button border-none font-medium line-height-3" role="menuitem" :aria-label="$t('endpoints.landing.topbar.language')" aria-haspopup="true" @click="toggleLang"> {{ $t("endpoints.landing.topbar.language") }}<i class="pi pi-arrow-down text-xs ml-1" aria-hidden="true"></i> </Button>

            <Menu ref="user" :model="overlayUserItems" :popup="true" />
            <Button id="topbar-user-button" class="p-button border-none font-medium line-height-3" role="menuitem" style="margin-left: 1rem" :aria-label="$t('app.topBar.myprofile')" aria-haspopup="true" @click="toggleUser"> {{ $t("app.topBar.myprofile") }}<i class="pi pi-arrow-down text-xs ml-1" aria-hidden="true"></i> </Button>

            <Button v-if="showDemoUserButton && userMode == 'teacher'" :icon="tempUserIcon" :class="tempUserButton" aria-label="Temporal user" @click="tempUser" />
        </div>
    </nav>
</template>

<script>
import { logoutUser, isLoggedIn, getUserInfo } from "./utils/auth";
import { RepositoryFactory } from "./repositories/RepositoryFactory";
import { env_config } from "../config";

const UsersRepository = RepositoryFactory.get("users");

export default {
    emits: ["menu-toggle", "topbar-menu-toggle"],
    data() {
        // let { logo, email } = getUserInfo();
        return {
            logo: "",
            overlayUserItems: [],
            overlayLangItems: [
                {
                    label: "Español",
                    command: () => {
                        this.$root.$i18n.locale = "es";
                        localStorage.setItem("lang", "es");
                    }
                },
                {
                    label: "English",
                    command: () => {
                        this.$root.$i18n.locale = "en";
                        localStorage.setItem("lang", "en");
                    }
                }
            ],
            // email: "",
            // adminEmail: env_config.admin_username,
            userMode: "student",
            showDemoUserButton: env_config.DEMO_USER_BUTTON,
            tempUserIcon: "",
            tempUserButton: "",
            tempUserExists: false
        };
    },
    computed: {
        darkTheme() {
            return this.$appState.darkTheme;
        }
    },
    watch: {
        "$i18n.locale": function () {
            this.onLangChange();
        }
    },
    created() {
        this.onLangChange();
        if (isLoggedIn()) {
            let { user_mode } = getUserInfo();
            // this.logo = logo;
            this.logo = "https://www.bemyvega.com/wp-content/uploads/2023/10/Black-_-Logotipo-horizontal-Bemyvega-1024x206.png";
            this.userMode = user_mode;
            // this.email = email;
            // this.getTempUser();
            // console.log(env_config.DEMO_USER_BUTTON);
            if (this.userMode == "teacher") {
                this.getTempUser();
            }
        }
        // console.log(this.email);
        // console.log(this.adminEmail);
    },
    methods: {
        async getTempUser() {
            let response;
            response = await UsersRepository.getTemporalUser();

            if (response.data == null) {
                this.tempUserIcon = "pi pi-user-plus";
                this.tempUserButton = "p-button-rounded p-button-success ml-2";
                this.tempUserExists = false;
            } else {
                this.tempUserIcon = "pi pi-user-minus";
                this.tempUserButton = "p-button-rounded p-button-danger ml-2";
                this.tempUserExists = true;
            }
            // console.log(this.tempUserExists);
        },
        async tempUser() {
            console.log(this.tempUserExists);
            if (this.tempUserExists) {
                await UsersRepository.getTemporalUser("delete");
                alert("\nDemo user removed successfully!\n");
            } else {
                await UsersRepository.getTemporalUser("create");
                alert("\nEmail: demo@bemyvega.com\n\nPassword: demo\n");
            }
            await this.getTempUser();
        },

        onMenuToggle(event) {
            this.$emit("menu-toggle", event);
        },
        onTopbarMenuToggle(event) {
            this.$emit("topbar-menu-toggle", event);
        },
        topbarImage() {
            // return this.$appState.darkTheme ? "images/Logo_139x158.svg" : "images/Logo_139x158.svg";
            return this.$appState.darkTheme ? "images/Logo_148x158N.svg" : "images/Logo_148x158N.svg";
        },
        toggleLang(event) {
            this.$refs.lang.toggle(event);
        },
        toggleUser(event) {
            this.$refs.user.toggle(event);
        },
        onLangChange() {
            this.overlayUserItems = [
                {
                    label: this.$t("app.topBar.profile"),
                    icon: "pi pi-user",
                    command: () => {
                        this.$router.push("/profile");
                    }
                },
                {
                    label: this.$t("app.topBar.logout"),
                    icon: "pi pi-sign-out",
                    command: () => {
                        this.$router.push("/");
                        logoutUser();
                    }
                }
            ];
        }
    }
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 992px) {
    #topbar-user-button {
        margin-left: 0rem !important;
        margin-top: 1rem !important;
        width: 100%;
    }
    #language-button {
        width: 100%;
    }
}
</style>
