<template>
    <!-- <svg version="1.1" :viewBox="viewBox" :width="width" :height="height">
        <defs id="defs29">
            <clipPath id="clipPath39" clipPathUnits="userSpaceOnUse"><path d="M 0,595.276 H 841.89 V 0 H 0 Z" /></clipPath>
        </defs>
        <g id="g31" inkscape:groupmode="layer" inkscape:label="20200803-bemyvega-logotipo-T" transform="matrix(1.3333333,0,0,-1.3333333,0,793.70133)">
            <g id="g33" transform="translate(-37.316768,20.301929)">
                <g id="g35" clip-path="url(#clipPath39)">
                    <g id="g41" transform="matrix(0.88521231,0,0,0.88521231,90.506222,494.28382)">
                        <path
                            id="path43"
                            d="m 0,0 c 24.846,0 45.056,20.209 45.056,45.053 0,24.843 -20.21,45.051 -45.056,45.051 -24.843,0 -45.054,-20.208 -45.054,-45.051 0,-3.06 0.309,-6.112 0.921,-9.083 0.823,-4.024 4.76,-6.591 8.765,-5.779 4.015,0.829 6.603,4.748 5.777,8.765 -0.409,1.993 -0.616,4.037 -0.616,6.097 0,16.657 13.552,30.206 30.207,30.206 16.653,0 30.209,-13.549 30.209,-30.206 C 30.209,28.395 16.653,14.847 0,14.847 c -15.547,0 -28.191,-12.651 -28.191,-28.199 0,-15.543 12.644,-28.191 28.191,-28.191 4.099,0 7.422,3.319 7.422,7.421 0,4.104 -3.323,7.425 -7.422,7.425 -7.358,0 -13.347,5.987 -13.347,13.345 C -13.347,-5.987 -7.358,0 0,0"
                            :style="'fill: ' + color + '; fill-opacity: 1; fill-rule: nonzero; stroke: none'"
                        />
                    </g>
                    <g id="g45" transform="matrix(0.86590426,0,0,0.86590426,99.92569,545.8533)">
                        <path id="path47" d="m 0,0 c 0.454,0 0.896,-0.051 1.326,-0.134 -3.611,2.92 -8.203,4.676 -13.209,4.676 -11.612,0 -21.027,-9.414 -21.027,-21.026 0,-11.614 9.415,-21.029 21.027,-21.029 11.614,0 21.029,9.415 21.029,21.029 0,3.317 -0.789,6.442 -2.158,9.233 C 6.859,-11.01 3.792,-14.023 0,-14.023 c -3.872,0 -7.011,3.139 -7.011,7.012 C -7.011,-3.139 -3.872,0 0,0" :style="'fill: ' + color + '; fill-opacity: 1; fill-rule: nonzero; stroke: none'" />
                    </g>
                </g>
            </g>
        </g>
    </svg> -->
    <svg id="svg1" :viewBox="viewBox" :width="width" :height="height" version="1.1" xml:space="preserve" inkscape:export-filename="Logo_148x158N.svg" inkscape:export-xdpi="69.972923" inkscape:export-ydpi="69.972923" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
        <sodipodi:namedview id="namedview1" pagecolor="#ffffff" bordercolor="#000000" borderopacity="0.25" inkscape:showpageshadow="2" inkscape:pageopacity="0.0" inkscape:pagecheckerboard="0" inkscape:deskcolor="#d1d1d1" inkscape:document-units="mm" inkscape:zoom="2.0384476" inkscape:cx="107.67998" inkscape:cy="80.208096" inkscape:window-width="1920" inkscape:window-height="1044" inkscape:window-x="1920" inkscape:window-y="0" inkscape:window-maximized="1" inkscape:current-layer="g1" />
        <defs id="defs1" />
        <g id="layer1" inkscape:label="Layer 1" inkscape:groupmode="layer" transform="translate(-70.698222,-120.03423)">
            <g id="g1" transform="matrix(0.26458333,0,0,0.26458333,10.652723,90.302972)">
                <g id="g2" transform="matrix(0.72888,0,0,0.72888,226.94109,112.3809)">
                    <path
                        id="path9"
                        d="m 95.67,216.44 c -7.53,1.24 -15.65,-1.21 -22.34,-6.89 l 0.8,-0.95 c 7.45,6.33 16.72,8.47 24.78,5.75 6.92,-2.34 11.83,-7.94 13.45,-15.37 0.3,-1.35 0.42,-2.73 0.49,-3.78 0.18,-2.58 1.83,-5.36 4.22,-7.1 3.35,-2.58 8.66,-2.56 12.52,-1.67 l 39.96,9.16 9.56,-41.32 c 1.37,-4.79 1.13,-8.46 -0.71,-10.96 -2.09,-2.84 -6.38,-4.35 -12.76,-4.51 h -0.05 c -1.44,-0.16 -2.53,-0.38 -3.52,-0.72 L 162,138.05 c -14.23,-6.7 -10.96,-19.85 -10.93,-19.98 1.4,-5.59 4.33,-9.56 8.71,-11.8 5.45,-2.78 11.48,-1.97 13.55,-1.14 3.83,1.54 9.09,3.64 13.2,1.49 2.86,-1.5 4.94,-4.99 6.18,-10.36 0.09,-0.37 0.23,-1 0.44,-1.88 1.2,-5.06 4.36,-18.43 8.69,-38.98 l -40.05,-9.19 c -3.42,-0.78 -7.98,-3.08 -10.09,-5.96 -1.94,-2.65 -2.37,-6.45 -1.07,-9.46 0.54,-1.26 0.96,-2.5 1.24,-3.69 1.82,-7.7 -0.73,-15.35 -6.82,-20.47 -6.79,-5.7 -16.21,-7 -24.58,-3.39 L 119.98,2.1 c 8.8,-3.79 18.71,-2.42 25.86,3.58 6.46,5.42 9.17,13.54 7.23,21.7 -0.3,1.26 -0.74,2.57 -1.31,3.89 -1.11,2.58 -0.73,5.96 0.93,8.24 1.91,2.61 6.25,4.77 9.37,5.49 l 41.24,9.46 -0.13,0.6 c -4.39,20.91 -7.61,34.5 -8.82,39.61 -0.21,0.87 -0.36,1.5 -0.44,1.87 -1.34,5.83 -3.56,9.48 -6.81,11.18 -4.62,2.42 -10.18,0.19 -14.24,-1.43 -1.82,-0.73 -7.51,-1.47 -12.53,1.09 -4.05,2.07 -6.77,5.77 -8.07,11 -0.13,0.51 -3.02,12.28 10.22,18.53 0.89,0.29 1.88,0.49 3.21,0.64 6.87,0.17 11.35,1.81 13.71,5.01 2.08,2.82 2.38,6.86 0.91,12.01 l -9.83,42.5 -41.16,-9.44 c -3.41,-0.78 -8.47,-0.87 -11.5,1.46 -1.74,1.27 -3.56,3.66 -3.73,6.19 -0.07,1.09 -0.2,2.53 -0.51,3.96 -1.72,7.87 -6.92,13.8 -14.27,16.28 -1.19,0.4 -2.41,0.7 -3.64,0.91 z"
                        :fill="color"
                    />
                    <path
                        id="path10"
                        d="m 143.36,115.79 c -2.26,9.85 3.91,19.66 13.77,21.93 6.05,1.19 5.65,-0.24 9.31,0.52 4.7,1.08 7.72,5.59 7.01,10.27 l -10.73,46.8 -48.03,-11.01 c -4.47,-1.03 -9.21,2.3 -10.28,6.99 -0.75,3.64 0.67,3.26 -0.51,9.3 -2.26,9.85 -12.09,16 -21.95,13.73 -9.87,-2.26 -16.03,-12.08 -13.77,-21.93 1.53,-5.7 2.74,-5.06 3.63,-8.58 1.09,-4.69 -1.58,-9.72 -6.24,-10.78 l -45.74,-10.49 10.73,-46.8 c 0.71,-4.69 -2.31,-9.19 -7.01,-10.27 -3.66,-0.76 -3.27,0.67 -9.31,-0.52 C 4.38,102.69 -1.79,92.87 0.47,83.02 c 2.26,-9.86 12.09,-16 21.95,-13.73 5.7,1.53 5.07,2.74 8.6,3.63 4.69,1.08 9.39,-1.69 10.8,-6.22 L 52.55,19.91 99.47,30.67 c 4.74,1.09 9.21,-2.3 10.28,-6.99 0.76,-3.65 -0.67,-3.27 0.51,-9.3 2.26,-9.85 12.09,-16 21.95,-13.73 9.87,2.26 16.03,12.08 13.77,21.93 -1.52,5.69 -2.73,5.05 -3.63,8.58 -1.07,4.69 1.76,9.76 6.24,10.78 l 46.85,10.74 -10.73,46.79 c -1.4,4.53 -6.1,7.3 -10.8,6.22 -3.53,-0.89 -2.9,-2.1 -8.6,-3.63 -9.86,-2.26 -19.69,3.88 -21.95,13.73 z"
                        :fill="color"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "LogoSVG",
    props: {
        color: {
            type: String,
            // default: "#007680"
            default: "#0C0C0C"
        },
        viewBox: {
            type: String,
            // default: "0 0 139 158"
            default: "0 0 39.20562 41.803917"
        },
        width: {
            type: String,
            default: null
        },
        height: {
            type: String,
            default: null
        }
    }
};
</script>
